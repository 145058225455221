import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';
import Loading from './Loading';
import Logo from "../assets/images/logo.png";
import Iframe from 'react-iframe';

const pdfTypes = {
    userAgreement: "userAgreement",
    hipaaAgreement: "hipaaAgreement",
}

const AdminAgreements = () => {

    // get pdfType from url
    const urlParams = new URLSearchParams(window.location.search);
    const pdfTypeQuery = urlParams.get('pdfType');
    
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    const [physician, setPhysician] = useState(null);
    const [pdfType, setPdfType] = useState(pdfTypes[pdfTypeQuery] ?? 'userAgreement')
    const [pdfs, setPdfs] = useState({ userAgreement: null, hipaaAgreement: null })

    const getAdminProfile = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/admin/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setPhysician(response.adminProfile);
                    getDocuments()
                } else {
                    setMessage(response.message);
                }
                // setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
    }

    const getDocuments = () => {
        // setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/documents`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setPdfs({
                        userAgreement: response.user_agreement_pdf,
                        hipaaAgreement: response.hipaa_agreement_pdf,
                    })
                } else {
                    setMessage(response.message);
                }
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
    }

    useEffect(() => {
        getAdminProfile();
    }, []);

    const getPendingAgreementText = (pdfType) => {
        if (pdfType === pdfTypes.userAgreement) {
            return "User Agreement"
        } else if (pdfType === pdfTypes.hipaaAgreement) {
            return "Hipaa Agreement"
        }
    }

    return (
        <>
            <main
                className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${ready ? "" : "hidden"
                    }`}
            >
                <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
                    <NavLink to="/">
                        <img className="h-20" src={Logo} alt="GRO Track" />
                    </NavLink>
                    <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
                        {physician && (physician.clinic.name)}
                    </h1>
                </nav>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {success && (
                    <p className="mt-8 text-center text-green-600">{success}</p>
                )}

                <section className="flex">
                    <AdminSidebar />
                    <div className="w-full p-4 flex flex-col items-center justify-center" style={{ minHeight: 'calc(100vh - 138px)' }}>
                        <div className='mb-4 flex justify-center'>

                            <button className={`${pdfType === pdfTypes.userAgreement ? `bg-accent-1 text-white` : `bg-transparent text-accent-1 border border-accent-1`}  mx-2  focus:outline-none  px-16 py-3 rounded text-xl`} onClick={(e) => setPdfType(pdfTypes.userAgreement)}>{getPendingAgreementText(pdfTypes.userAgreement)}</button>
                            <button className={`${pdfType === pdfTypes.hipaaAgreement ? `bg-accent-1 text-white` : `bg-transparent text-accent-1 border border-accent-1`} mx-2  focus:outline-none  px-16 py-3 rounded text-xl`} onClick={(e) => setPdfType(pdfTypes.hipaaAgreement)}>{getPendingAgreementText(pdfTypes.hipaaAgreement)}</button>

                        </div>

                        <div className='flex justify-center items-center w-full' style={{flexGrow: 1}}>


                        {typeof(pdfs[pdfType]) === "object" ? ( 

                            // if agreement has not been signed
                            <h3 className="text-center text-text-2 capitalize text-xl" >
                                Please click  
                                  <NavLink 
                                    className="underline mx-2" 
                                    to={`/onboarding/${getPendingAgreementText(pdfType)?.toLowerCase()?.split(" ")?.join("-")}?callback=/admin/agreements?pdfType=${pdfType}`}
                                    >
                                    Here
                                </NavLink> 
                                to sign the {getPendingAgreementText(pdfType)}
                            </h3>

                        ) : (

                            // if agreement has been signed
                            <Iframe url={pdfs[pdfType]}
                            width="80%"
                            height="650px"
                            display="block"
                            />

                        )}
                        </div>

                    </div>
                </section>
            </main>
            <Loading message={"Loading  Details"} ready={ready} />
        </>
    )
}

export default AdminAgreements;