import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import ShowHeadAvatar from './ShowHeadAvatar';
import GroIndexGraph from './GroIndexGraph';
import ImageLoader from '../ImageLoader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const CloseupImageView = ({ analysis, base_analysis, handleTriggerRelaod, setReady }) => {
	const history = useHistory();
	const location = useLocation()
    const { patient_id, analysis_id, subType, graphType } = useParams();
	const analysis_id_int = parseInt(analysis_id);
	const [overlayImage, setOverlayImage] = useState(null)

	useEffect(() => {
		const secondaryImage = analysis?.images?.closeup?.ml?.[subType];
		if(!secondaryImage){
			setOverlayImage(null)
		} else if (secondaryImage?.length) {
			const requiredImage = secondaryImage?.find(img => graphType === 'hair_thinning' ? img?.paint === 7 : img?.paint === 3);
			setOverlayImage(requiredImage) 
		} else {
			setOverlayImage(analysis?.images?.closeup?.ml?.[subType])
		}
	}, [analysis, location]);

	const handleRoute = () => {
		history.push({
			pathname: `/patient/${patient_id}/consult/${analysis_id_int}/compare/closeup/${subType}`,
			state: { 
				showMaskAssessment: overlayImage?.paint === 7 ? true : false
			 },
		  })
	}

	return (
		<div style={{flexGrow: 1}}>
			{/* <div class="text-center font-bold text-base font-sans tracking-wider text-text-15 opacity-80 mt-12">
				{ analysis?.id === base_analysis?.id ? 'Closeup Thinning Hair Assessment' : 'Closeup Hair Growth Tracking'}
			</div> */}
			<div className='text-center font-bold sm:text-lg font-sans tracking-wider opacity-80 text-text-15 mt-1 sm:my-2 capitalize'>
				{subType?.toUpperCase()}
			</div>

			{!analysis?.images?.closeup?.raw?.[subType] && (
					<p className="mt-3 xs:mt-1 block h-60 xs:h-80 sm:hidden text-center text-gray-400">No image found</p>
			)}

			<div className='lg:mx-15 xl:mx-20 grid grid-cols-12 sm:grid-cols-3 gap-4 sm:gap-5 jusitfy-center'>
				<div className='w-full col-span-5 sm:col-span-1 pt-7'>
					{
						base_analysis && analysis?.images?.closeup?.raw?.[subType]?.status !== 'invalid' && <GroIndexGraph graphType={graphType || 'hair_thinning'} analysis={analysis} base_analysis={base_analysis} forType={subType} mainType="closeup"/>
					}
				</div>
				<div className='w-full col-span-7 sm:col-span-1 flex justify-center align-center'>
					{analysis?.images?.closeup.raw && <ImageLoader setReady={setReady} img={analysis?.images?.closeup?.raw?.[subType]} secondaryImg={overlayImage} onDoubleClick={handleRoute} />}
				</div>
				<div className='col-span-2 block sm:hidden'></div>
				<div className='w-full col-span-8 sm:col-span-1 flex justify-evenly flex-row'>
					<ShowHeadAvatar handleTriggerRelaod={handleTriggerRelaod} analysis={analysis} forType={subType}  mainType="closeup" />
				</div>
				<div className='col-span-2 block sm:hidden'></div>
			</div>
		</div>
	)
}

export default CloseupImageView