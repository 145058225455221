import React, { createContext, useContext, useEffect, useState } from 'react'

const ClinicContext = createContext({})

export const useClinicContext = () => {
    return useContext(ClinicContext)
}

export const ClinicProvider = ({ children }) => {

    const [clinic, setClinic] = useState(null)
    
    const handleSetClinic = (data) => {
        setClinic(data)
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/clinic`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		})
		.then((response) => response.json())
		.then((response) => {
            if(response.success){
            setClinic(response.data)
            }
		})
		.catch((error) => {
			console.error(error);
		});
    }, [])


    return (
        <ClinicContext.Provider value={{ clinic, handleSetClinic }}>
            {children}
        </ClinicContext.Provider>

    )
}

export default ClinicContext