import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PatientSidebar from '../components/PatientSidebar';

export default function PatientHealthInfo() {
  const { id } = useParams();
  const [healthDropdown, setHealthDropdown] = useState(true);
  const [examDrop, setExamDrop] = useState(false);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(false);
  const [healthInfo, setHealthInfo] = useState(null);
  const [editHealthInfo, setEditHealthInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const isHealthInfoValid = () => {
    return true;
  };
  const isHealthOpen = () => {
    return true;
  };

  const saveHealthInfo = () => {
    if (isHealthInfoValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/patient/${id}/health-info`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(editHealthInfo),
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            setHealthInfo(response.medicalInfo);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch(error => {
          console.error(error);
          setMessage('Some Error Occured. Please Try Again Later');
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/health-info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setHealthInfo(response.medicalInfo);
          setEditHealthInfo({
            ...response.medicalInfo,
            medicalConditions: [...response.medicalInfo.medicalConditions],
            historyOfAlopecia: [...response.medicalInfo.historyOfAlopecia],
            // medications: [...response.medicalInfo.medications],
            recentlyLostWeight: { ...response.medicalInfo.recentlyLostWeight },
            recentlyChildBirth: { ...response.medicalInfo.recentlyChildBirth },
            recentlyDiet: { ...response.medicalInfo.recentlyDiet },
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
        setReady(true);
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={1} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex">
          <PatientSidebar
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            isHealthOpen={isHealthOpen}
            id={id}
          />
          {healthInfo && (
            <div className="mx-auto max-w-5xl">
              {patientReferrals ? (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${healthInfo.firstName} ${healthInfo.lastName} referred by ${patientReferrals.referred_by}`}
                </h1>
              ) : (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${healthInfo.firstName} ${healthInfo.lastName}`}
                </h1>
              )}
              <form
                className="lg:mx-16 flex flex-col"
                onSubmit={event => {
                  event.preventDefault();
                  saveHealthInfo();
                }}
              >
                <div className="mt-4 flex justify-between items-center">
                  <button
                    type="button"
                    className="px-8 py-1 bg-accent-1 rounded-lg text-text-3
              cursor-pointer focus:outline-none"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  >
                    {editMode ? 'Cancel' : 'Edit'}
                  </button>
                  <p className="mx-4">PATIENT INFORMATION</p>
                  <button
                    className="px-8 py-1 bg-accent-1 rounded-lg text-text-3
              cursor-pointer focus:outline-none"
                    disabled={!editMode}
                  >
                    SAVE
                  </button>
                </div>
                <section className="mt-8">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <p>Health Condition:</p>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="healthCondition"
                        value="Good"
                        checked={
                          editMode
                            ? editHealthInfo.healthCondition === 'Good'
                            : healthInfo.healthCondition === 'Good'
                        }
                        onChange={() => {
                          if (editMode) {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              healthCondition: 'Good',
                            });
                          }
                        }}
                      />
                      <p className="ml-4">Good</p>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="healthCondition
                    healthCondition"
                        value="Average"
                        checked={
                          editMode
                            ? editHealthInfo.healthCondition === 'Average'
                            : healthInfo.healthCondition === 'Average'
                        }
                        onChange={() => {
                          if (editMode) {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              healthCondition: 'Average',
                            });
                          }
                        }}
                      />
                      <p className="ml-4">Average</p>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="healthCondition"
                        value="Medical Conditions"
                        checked={
                          editMode
                            ? editHealthInfo.healthCondition ===
                              'Medical Conditions'
                            : healthInfo.healthCondition ===
                              'Medical Conditions'
                        }
                        onChange={() => {
                          if (editMode) {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              healthCondition: 'Medical Conditions',
                            });
                          }
                        }}
                      />
                      <p className="ml-4">Medical Conditions</p>
                    </label>
                  </div>
                  <div className="mt-4 border-t-2 border-background-2 pt-4">
                    <p>List medical conditions</p>
                    <div className="mt-2 grid grid-cols-3 gap-4">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.medicalConditions[0]
                            : healthInfo.medicalConditions[0]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            medicalConditions: [
                              event.target.value,
                              editHealthInfo.medicalConditions[1],
                              editHealthInfo.medicalConditions[2],
                            ],
                          });
                        }}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.medicalConditions[1]
                            : healthInfo.medicalConditions[1]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            medicalConditions: [
                              editHealthInfo.medicalConditions[0],
                              event.target.value,
                              editHealthInfo.medicalConditions[2],
                            ],
                          });
                        }}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.medicalConditions[2]
                            : healthInfo.medicalConditions[2]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            medicalConditions: [
                              editHealthInfo.medicalConditions[0],
                              editHealthInfo.medicalConditions[1],
                              event.target.value,
                            ],
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4">
                    <p>Family history of Alopecia</p>
                    <div className="mt-2 grid grid-cols-3 gap-4">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.historyOfAlopecia[0]
                            : healthInfo.historyOfAlopecia[0]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            historyOfAlopecia: [
                              event.target.value,
                              editHealthInfo.historyOfAlopecia[1],
                              editHealthInfo.historyOfAlopecia[2],
                            ],
                          });
                        }}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.historyOfAlopecia[1]
                            : healthInfo.historyOfAlopecia[1]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            historyOfAlopecia: [
                              editHealthInfo.historyOfAlopecia[0],
                              event.target.value,
                              editHealthInfo.historyOfAlopecia[2],
                            ],
                          });
                        }}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editHealthInfo.historyOfAlopecia[2]
                            : healthInfo.historyOfAlopecia[2]
                        }
                        disabled={!editMode}
                        onChange={event => {
                          setEditHealthInfo({
                            ...editHealthInfo,
                            historyOfAlopecia: [
                              editHealthInfo.historyOfAlopecia[0],
                              editHealthInfo.historyOfAlopecia[1],
                              event.target.value,
                            ],
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid sm:grid-cols-2 gap-4">
                    <div>
                      <p>
                        Have you recently lost or gained significant weight?
                      </p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="recentlyLostWeight"
                            value="Yes"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyLostWeight.answer ===
                                  'Yes'
                                : healthInfo.recentlyLostWeight.answer === 'Yes'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyLostWeight: {
                                    answer: 'Yes',
                                    description:
                                      editHealthInfo.recentlyLostWeight
                                        .description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4">Yes</p>
                        </label>
                        <input
                          type="text"
                          className="px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                          value={
                            editMode
                              ? editHealthInfo.recentlyLostWeight.description
                              : healthInfo.recentlyLostWeight.description
                          }
                          placeholder="How much"
                          disabled={!editMode}
                          onChange={event => {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              recentlyLostWeight: {
                                answer:
                                  editHealthInfo.recentlyLostWeight.answer,
                                description: event.target.value,
                              },
                            });
                          }}
                        />
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="recentlyLostWeight"
                            value="No"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyLostWeight.answer ===
                                  'No'
                                : healthInfo.recentlyLostWeight.answer === 'No'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyLostWeight: {
                                    answer: 'No',
                                    description:
                                      editHealthInfo.recentlyLostWeight
                                        .description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4">No</p>
                        </label>
                      </div>
                    </div>
                    <div>
                      <p>Have you recently had child birth?</p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="recentlyChildBirth"
                            value="Yes"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyChildBirth.answer ===
                                  'Yes'
                                : healthInfo.recentlyChildBirth.answer === 'Yes'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyChildBirth: {
                                    answer: 'Yes',
                                    description:
                                      editHealthInfo.recentlyChildBirth
                                        .description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4">Yes</p>
                        </label>
                        <input
                          type="text"
                          className="px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                          value={
                            editMode
                              ? editHealthInfo.recentlyChildBirth.description
                              : healthInfo.recentlyChildBirth.description
                          }
                          placeholder="How long ago"
                          disabled={!editMode}
                          onChange={event => {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              recentlyChildBirth: {
                                answer:
                                  editHealthInfo.recentlyChildBirth.answer,
                                description: event.target.value,
                              },
                            });
                          }}
                        />
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="recentlyChildBirth"
                            value="No"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyChildBirth.answer ===
                                  'No'
                                : healthInfo.recentlyChildBirth.answer === 'No'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyChildBirth: {
                                    answer: 'No',
                                    description:
                                      editHealthInfo.recentlyChildBirth
                                        .description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4">No</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid sm:grid-cols-2 gap-4">
                    <div>
                      <p>Have you recently gone on a diet?</p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="recentlyDiet"
                            value="Yes"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyDiet.answer === 'Yes'
                                : healthInfo.recentlyDiet.answer === 'Yes'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyDiet: {
                                    answer: 'Yes',
                                    description:
                                      editHealthInfo.recentlyDiet.description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4">Yes</p>
                        </label>
                        <input
                          type="text"
                          className="px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                          value={
                            editMode
                              ? editHealthInfo.recentlyDiet.description
                              : healthInfo.recentlyDiet.description
                          }
                          placeholder="How long ago"
                          disabled={!editMode}
                          onChange={event => {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              recentlyDiet: {
                                answer: editHealthInfo.recentlyDiet.answer,
                                description: event.target.value,
                              },
                            });
                          }}
                        />
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="recentlyDiet"
                            value="No"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyDiet.answer === 'No'
                                : healthInfo.recentlyDiet.answer === 'No'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyDiet: {
                                    answer: 'No',
                                    description:
                                      editHealthInfo.recentlyDiet.description,
                                  },
                                });
                              }
                            }}
                          />
                          <p className="ml-4">No</p>
                        </label>
                      </div>
                    </div>
                    <div>
                      <p>
                        Have you recently had extreme stressful life events?
                      </p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="recentlyStressful"
                            value="Yes"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyStressful === 'Yes'
                                : healthInfo.recentlyStressful === 'Yes'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyStressful: 'Yes',
                                });
                              }
                            }}
                          />
                          <p className="ml-4">Yes</p>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="recentlyStressful"
                            value="No"
                            checked={
                              editMode
                                ? editHealthInfo.recentlyStressful === 'No'
                                : healthInfo.recentlyStressful === 'No'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  recentlyStressful: 'No',
                                });
                              }
                            }}
                          />
                          <p className="ml-4">No</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid sm:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                      <p>When did you notice your hair loss?</p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <div></div>
                        <input
                          type="text"
                          className="px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                          placeholder="How long ago"
                          value={
                            editMode
                              ? editHealthInfo.noticeHairLoss
                              : healthInfo.noticeHairLoss
                          }
                          disabled={!editMode}
                          onChange={event => {
                            setEditHealthInfo({
                              ...editHealthInfo,
                              noticeHairLoss: event.target.value,
                            });
                          }}
                        />
                        <div></div>
                      </div>
                    </div>
                    <div>
                      <p>How much hair shading do you experience?</p>
                      <div className="mt-2 grid grid-cols-3 gap-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="hairShading"
                            value="Slight"
                            checked={
                              editMode
                                ? editHealthInfo.hairShading === 'Slight'
                                : healthInfo.hairShading === 'Slight'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  hairShading: 'Slight',
                                });
                              }
                            }}
                          />
                          <p className="ml-4">Slight</p>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="hairShading"
                            value="Moderate"
                            checked={
                              editMode
                                ? editHealthInfo.hairShading === 'Moderate'
                                : healthInfo.hairShading === 'Moderate'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  hairShading: 'Moderate',
                                });
                              }
                            }}
                          />
                          <p className="ml-4">Moderate</p>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="hairShading"
                            value="Severe"
                            checked={
                              editMode
                                ? editHealthInfo.hairShading === 'Severe'
                                : healthInfo.hairShading === 'Severe'
                            }
                            onChange={() => {
                              if (editMode) {
                                setEditHealthInfo({
                                  ...editHealthInfo,
                                  hairShading: 'Severe',
                                });
                              }
                            }}
                          />
                          <p className="ml-4">Severe</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t-2 border-background-2 pt-4 grid sm:grid-cols-2 gap-4">
                    <p>What is your main concern about your hair loss?</p>
                    <div className="flex">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="concernHairLoss"
                          value="Thinning Hair"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                                'Thinning Hair'
                              : healthInfo.concernHairLoss === 'Thinning Hair'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Thinning Hair',
                              });
                            }
                          }}
                        />
                        <p className="ml-4">Thinning Hair</p>
                      </label>
                      <label className="ml-8 flex items-center">
                        <input
                          type="radio"
                          name="concernHairLoss"
                          value="Balding"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss === 'Balding'
                              : healthInfo.concernHairLoss === 'Balding'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Balding',
                              });
                            }
                          }}
                        />
                        <p className="ml-4">Balding</p>
                      </label>
                      <label className="ml-8 flex items-center">
                        <input
                          type="radio"
                          name="concernHairLoss"
                          value="Receding hairline"
                          checked={
                            editMode
                              ? editHealthInfo.concernHairLoss ===
                                'Receding hairline'
                              : healthInfo.concernHairLoss ===
                                'Receding hairline'
                          }
                          onChange={() => {
                            if (editMode) {
                              setEditHealthInfo({
                                ...editHealthInfo,
                                concernHairLoss: 'Receding hairline',
                              });
                            }
                          }}
                        />
                        <p className="ml-4">Receding hairline</p>
                      </label>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading message={'Loading Patient Health Info'} ready={ready} />
    </>
  );
}
