import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';
import Loading from './Loading';
import Logo from "../assets/images/logo.png";
import EmbeddedYouTubeVideo from '../components/EmbeddedYoutubeVideo';
import moment from 'moment';
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

const AdminTrainingVideo = () => {
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    const [physician, setPhysician] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);

    const { video_title } = useParams();

    useEffect(() => {
        let chapter = chapters.find((chapter) => { 
            if(chapter.slug === video_title){
                return true;
            }
            return false;
        });
       setSelectedChapter(chapter);
    }, [video_title]);

    const chapters = [
        {
            'id': 1,
            'title': 'How to use GroTrack Camera',
            'slug': 'how_to_use_grotrack_camera',
            'video_id': 't6R2aeoRsug',
        },
        {
            'id': 2,
            'title': 'How to use Grotrack Software',
            'slug': 'how_to_use_grotrack_software',
            'video_id': 't6R2aeoRsug',
        }
    ];

    const getAdminProfile = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/admin/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setPhysician(response.adminProfile);
                } else {
                    setMessage(response.message);
                }
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
    }

    useEffect(() => {
        getAdminProfile();
    }, []);

    const [chaptersVideo, setChaptersVideo] = useState([]);
    const [selectedVideoChapter, setSelectedVideoChapter] = useState(0);
  
    const handleChapterSelect = (index) => {
        setSelectedVideoChapter(index);
    };
  
    useEffect(() => {
        const fetchData = async () => {
            fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&id=${selectedChapter.video_id}&key=AIzaSyC9x65GqYjdhee-1XlveD4mx40tgjbPgwk`)
            .then(response => response.json())
            .then(data => {
                const description = data.items[0].snippet.description;
                console.log(description);
                const chapters = description
                .split('\n')
                .filter(line => line.match(/^\d{1,2}:\d{2}/))
                .map(line => {
                    const [time, title] = line.match(/^(\d{1,2}:\d{2})\s*(.*)$/).slice(1);
                    return {
                    startOffset: moment.duration(time, 'mm:ss').asSeconds() / 60,
                    title: title.trim()
                    };
                });
                setChaptersVideo(chapters);
            });
        }
        if(selectedChapter?.video_id) {
            fetchData();
        }
    }, [selectedChapter]);

    return (
        <>
        <main
          className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
            ready ? "" : "hidden"
          }`}
        >
          <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
              <NavLink to="/">
                  <img className="h-20" src={Logo} alt="GRO Track" />
              </NavLink>
              <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
                  {physician && (physician.clinic.name) }
              </h1> 
          </nav>
          {message && <p className="mt-8 text-center text-red-600">{message}</p>}
          {success && (
            <p className="mt-8 text-center text-green-600">{success}</p>
          )}
          <section className="flex">
                <aside className="w-64 hidden lg:flex flex-col border-background-2 font-font-2 font-bold tracking-wide px-10">
                    <ul className="mt-8 grid text-text-2">
                        {chapters.map((chapter) => {
                            return (
                                <li className='border-b py-10'>
                                    <NavLink exact to={`/admin/training/${chapter.slug}`} activeClassName="text-accent-1">
                                        {chapter.title}
                                        {
                                            selectedChapter?.id === chapter.id &&
                                            <ShowVideoChapterList chaptersVideo={chaptersVideo} selectedVideoChapter={selectedVideoChapter} handleChapterSelect={handleChapterSelect} selectedChapter={selectedChapter} chapter={chapter} />
                                        }
                                    </NavLink>
                                </li>
                            )
                        })}
                    
                    </ul>
                </aside>
                <div className="w-full p-4 flex flex-col" style={{ minHeight: 'calc(100vh - 138px)' }}>
                    <h1 className='text-center text-2xl text-white mb-10'>{selectedChapter && (selectedChapter.title) }</h1>

                    <div >
                        {selectedChapter && chaptersVideo[selectedVideoChapter]?.startOffset >=0 && <EmbeddedYouTubeVideo videoId={selectedChapter.video_id} key={selectedChapter.id} start={chaptersVideo[selectedVideoChapter].startOffset}  end={chaptersVideo[selectedVideoChapter + 1].startOffset - 1} />}
                    </div>
                </div>
            </section>
        </main>
        <Loading message={"Loading  Details"} ready={ready} />
      </>
    )
}

const ShowVideoChapterList = ({ chaptersVideo, selectedVideoChapter, handleChapterSelect, selectedChapter, chapter}) => {
    const [show, setShow] = useState(false);
    if(!show){
        return (
            <div className='text-center '>
                <button className={`w-full py-2 text-left text-gray-500 flex justify-center`} onClick={(e) => {e.preventDefault(); setShow(prev => !prev)}}><AiOutlineDown /></button>
            </div>
        )
    }
    return (
        <>
            <div className='text-center '>
                <button className={`w-full py-2 text-left text-gray-500 flex justify-center`} onClick={(e) => {e.preventDefault(); setShow(prev => !prev)}}><AiOutlineUp /></button>
            </div>
            <div className='h-96 overflow-auto'>
                <ul>
                    {chaptersVideo &&
                    chaptersVideo.map((chap, index) => (
                        <li>
                            <button
                            key={index}
                            className={`w-full py-2 text-left text-gray-500 ${
                                selectedVideoChapter === index && chapter.id === selectedChapter.id ? 'text-accent-1' : ''
                            }`}
                            onClick={() => handleChapterSelect(index)}
                            >
                            {chap.title}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}

export default AdminTrainingVideo;