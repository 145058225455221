import { React } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";

export default function NotFound() {
  return (
    <main className="flex flex-col p-4">
      <section className="my-auto flex flex-col text-text-2">
        <NavLink to="/" className="mx-auto">
          <img className="h-32" src={Logo} alt="GRO Track" />
        </NavLink>
        <h1 className="mt-8 text-2xl text-center">404 Not Found</h1>
      </section>
      <footer className="py-4 border-t-2 border-accent-2 text-center">
        GRO technologies
      </footer>
    </main>
  );
}
