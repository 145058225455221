import { React, useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
// import dayjs from "dayjs";
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import ViewProcedure from '../components/ViewProcedure';
import ViewMedication from '../components/ViewMedication';
import ViewProduct from '../components/ViewProduct';
import ConsentModal from '../components/ConsentModal';
import ProtocolForm from './ProtocolForm';
import ClinicalNotes from './ClinicalNotes';
import ConsentForm from '../components/ConsentForm'
import {GoPrimitiveDot} from 'react-icons/go'
import InfoForm from '../components/InfoForm';
import ProductConsentForm from '../components/ProductConsentForm'

export default function TreatmentPlan() {
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [treatmentPlan, setTreatmentPlan] = useState(null);
  const [message, setMessage] = useState('');
  const [showConsent, setShowConsent] = useState(false);
  const [ready, setReady] = useState(false);
  const [procedureTemplates, setprocedureTemplates] = useState([]);
  const [productTemplates, setproductTemplates] = useState([]);
  const [medicationTemplates, setMedicationTemplates] = useState([]);
  const [hasConsent, sethasConsent] = useState(false);
  const [modalConsent, setModalConsent] = useState(null);
  const [patientReferrals, setPatientReferrals] = useState(null);

  const [openProcedureForm,setOpenProcedureForm] = useState({open:false, id:null})
  const [openNotes,setOpenNotes] = useState({open:false, id:null})
  const [consent,setConsent] = useState({open:false, id:null})

  const [openProductNotes,setOpenProductNotes] = useState({open:false, id:null})
  const [openInfo,setOpenInfo] = useState({open:false, id:null})

  const [filterType,setFilterType] = useState('All')

  useEffect(() => {
    // other code   
    getTreatmentPlans()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  const getTreatmentPlans = () => {
    setReady(false)
    fetch(`${process.env.REACT_APP_API}/patient/${id}/show_treatment_plans`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({status: filterType})
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          console.log(response)
          setTreatmentPlan(response.treatmentPlans)
        } 
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        setReady(true);
      });
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        // setReady(true);
      });
  }, [id]);

  // const getTotalFee = () => {
  //   let fee = 0;
  //   for (const procedure of treatmentPlan.procedures) {
  //     fee += Number(procedure.fee);
  //   }
  //   for (const medication of treatmentPlan.medications) {
  //     fee += Number(medication.fee);
  //   }
  //   for (const product of treatmentPlan.products) {
  //     fee += Number(product.fee);
  //   }
  //   return fee;
  // };

  // const addTreatmentPlan = () => {
  //   // if (treatmentPlan.id) return;
  //   if (
  //     !treatmentPlan.procedures.length &&
  //     !treatmentPlan.medications.length &&
  //     !treatmentPlan.products.length
  //   ) {
  //     return alert('Please add atlest 1 treatment');
  //   }
  //   // if (
  //   //   !treatmentPlan.consentForm ||
  //   //   !treatmentPlan.signature ||
  //   //   !treatmentPlan.date
  //   // ) {
  //   //   setShowConsent(true);
  //   //   return;
  //   // }
  //   setReady(false);
  //   fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan`, {
  //     method: treatmentPlan.id ? 'PUT' : 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  //     },
  //     body: JSON.stringify({
  //       ...treatmentPlan,
  //       fee: getTotalFee(),
  //     }),
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.success) {
  //         setMessage('');
  //         window.location.reload();
  //         // fetchTreatmentPlan(id);
  //       } else {
  //         setMessage(response.message);
  //         setReady(true);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       setMessage('Some Error Occured. Please Try Again Later');
  //       setReady(true);
  //     });
  // };

  const getTemplates = () => {
    fetch(`${process.env.REACT_APP_API}/clinic/templates`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setprocedureTemplates(response.procedures);
          setMedicationTemplates(response.medications);
          setproductTemplates(response.products);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(
          'Unable to load Treatment Templates. Please Try Again Later'
        );
      });
    // .finally(() => {
    //   setReady(true);
    // });
  };

  async function fetchdraftTreatment() {
    const res = await fetch(
      `${process.env.REACT_APP_API}/patient/${id}/fetchdraftTreatment`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        // body: JSON.stringify({
        //   change,
        // }),
      }
    ).then(response => response.json());

    return res.draftTreatmentPlans;
  }

  const changePriority = async (type, index, change) => {
    if (
      (change === -1 && index > 0) ||
      (change === 1 && index < treatmentPlan[type].length - 1)
    ) {
      const draftTreatmentId = await fetchdraftTreatment();

      fetch(
        `${process.env.REACT_APP_API}/treatment_plan/${draftTreatmentId}/${treatmentPlan[type][index].id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          body: JSON.stringify({
            change,
          }),
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            const treatments = [...treatmentPlan[type]];
            const treatment = treatments[index];
            // treatments[index].priority = treatments[index].priority + change;
            // treatments[index + change].priority = treatments[index + change].priority - change;
            treatments[index] = treatments[index + change];
            treatments[index + change] = treatment;

            setTreatmentPlan({ ...treatmentPlan, [type]: treatments });
            // console.log(treatments)
          } else {
            setMessage(response.message);
          }
        })
        .catch(error => {
          console.error(error);
          setMessage('Some Error Occured. Please Try Again Later');
        });
    }
  };

  const duplicate = (sid, type) => {
    const plan = treatmentPlan[type].filter(plan => plan.id === sid)[0];
    setTreatmentPlan({
      [type]: treatmentPlan[type].push({
        // id: `draft-${Math.floor(Math.random() * 100 + 1)}`,
        ...plan,
      }),
      ...treatmentPlan,
    });
    // saveTreatmentPlan();
    // fetchTreatmentPlan(id);
  };
  const saveTreatment = treatment => {
    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/treatment_plan/${id}/${treatment.Tid}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(treatment),
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          fetchTreatmentPlan(id);
        } else {
          setMessage(response.message);
          setReady(true);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
        setReady(true);
      });
  };

  const deleteTreatment = (sid, type, index) => {
    if (!sid) {
      let newPlans = [...treatmentPlan[type]];
      newPlans.splice(index, 1);
      setTreatmentPlan({
        ...treatmentPlan,
        [type]: newPlans,
      });
      return;
    }
    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/treatment_plan/${treatmentPlan.id}/${sid}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          fetchTreatmentPlan(id);
        } else {
          const plans = treatmentPlan[type].filter(plan => plan.id !== sid);
          setTreatmentPlan({
            ...treatmentPlan,
            [type]: plans,
          });
          setReady(true);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
        setReady(true);
      });
  };

  const fetchTreatmentPlan = (id, isNew) => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          console.log(response.treatmentPlans)
          setFirstName(response.firstName);
          setLastName(response.lastName);
          if (!isNew && response.treatmentPlans[0]) {
            setTreatmentPlan(response.treatmentPlans[0]);
            sethasConsent(true);
          } else {
            setTreatmentPlan({
              procedures: [],
              medications: [],
              products: [],
              fee: '',
              // consentForm: "",
              // date: "",
              // signature: "",
              clinicalNotesBody: '',
              clinicalNotesSignature: '',
              clinicalNotesDate: '',
            });
          }
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        setReady(true);
      });
  };

  //useEffect(() => console.log(modalConsent), [modalConsent]);

  useEffect(() => {
    //fetchTreatmentPlan(id, false);
    getTemplates();
  }, [id]);

  useEffect(() => {
    if (
      treatmentPlan &&
      treatmentPlan.consentForm &&
      treatmentPlan.signature &&
      treatmentPlan.date
    ) {
      sethasConsent(true);
    } else {
      sethasConsent(false);
    }
  }, [treatmentPlan]);

  const filters = [
    'All',
    'Completed',
    'Scheduled',
    'Pending'
  ]

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={3} />

        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section>
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          <div className="mt-2 flex items-center md:justify-center flex-col md:flex-row">
            <NavLink
              exact
              to={`/patient/treatment-plan/view/${id}`}
              activeClassName="text-text-2"
            >
              GRO Plan
            </NavLink>
          </div>

          <div style={{width:'40%',margin:'0 auto',marginTop:'1.5rem',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            {
              filters.map(item => {
                return(
                  <div onClick={()=>setFilterType(item)} style={{display:'flex',alignItems:'center',cursor:'pointer'}}>
                    <GoPrimitiveDot style={filterType===item ? {fontSize:'20px',marginRight:'0.2rem',color:'#13C4B3'} : {fontSize:'20px',marginRight:'0.2rem',color:'white'}}/>
                    <div>{item}</div>
                  </div>
                )
              })
            }
          </div>

          {treatmentPlan ? (
            <div
              className="mt-4 p-4 lg:px-20 flex flex-col"
              // style={{ height: 'var(--height-1)' }}
            >
              <div className="mt-6 px-20 flex justify-between text-lg text-text-2">
                <h2>
                  Procedures&nbsp;&nbsp;
                  <button
                    onClick={() => {
                      const tp = {
                        type: '',
                        brand: '',
                        tx: '',
                        interval: '',
                        area: '',
                        fee: '',
                        Tid: '',
                      };

                      setTreatmentPlan({
                        procedures: treatmentPlan.procedures.push(tp),
                        ...treatmentPlan,
                      });
                    }}
                  >
                    +
                  </button>
                </h2>
                {/* <h2>Status</h2> */}
              </div>
              {treatmentPlan.procedures.length > 0 && (
                <>
                  {/* <div className="mt-4 grid grid-cols-12 justify-items-center font-font-2 font-normal text-sm">
                    <h2 className="px-2">Priority</h2>
                    <div></div>
                    <h2 className="px-2">Brand</h2>
                    <h2 className="px-2"># Tx</h2>
                    <h2 className="px-2">Interval</h2>
                    <h2 className="px-2">Area</h2>
                    <h2 className="px-2">Fee</h2>
                    <h2 className="px-2">Edit</h2>
                    <h2 className="px-2">Consent</h2>
                    <h2 className="px-2">Pay</h2>
                    <h2 className="px-2">Schedule</h2>
                    <h2 className="px-2">Complete</h2>
                  </div> */}
                  {treatmentPlan.procedures.map((procedure, index) => {
                    return (
                      <>
                      <ViewProcedure
                        key={index}
                        index={index}
                        openProcedureForm={openProcedureForm}
                        setOpenProcedureForm={setOpenProcedureForm}
                        openNotes={openNotes}
                        setOpenNotes={setOpenNotes}
                        consent={consent}
                        setConsent={setConsent}

                        procedure={procedure}

                        setProcedure={data => {
                          let procedure = treatmentPlan.procedures;
                          procedure[index] = data;
                          setTreatmentPlan({
                            procedures: procedure,
                            ...treatmentPlan,
                          });
                        }}

                        changePriority={changePriority}
                        saveTreatment={saveTreatment}
                        deleteTreatment={deleteTreatment}
                        setShowConsent={setShowConsent}
                        templateProcedures={procedureTemplates}
                        duplicateTreatment={duplicate}
                        has_consent={hasConsent}
                        setModalConsent={setModalConsent}
                      />
                      {
                        openProcedureForm.open && openProcedureForm.id===procedure.id &&
                        <ProtocolForm
                        getTreatmentPlans={getTreatmentPlans}
                        procedure={procedure}
                        setProcedure={data => {
                          let procedure = treatmentPlan.procedures;
                          procedure[index] = data;
                          setTreatmentPlan({
                            procedures: procedure,
                            ...treatmentPlan,
                          });
                        }}
                         />
                      }
    
                      {
                        openNotes.open && openNotes.id===procedure.id &&
                        <ClinicalNotes procedure={procedure} getTreatmentPlans={getTreatmentPlans}  />
                      }

                      {
                        consent.open && consent.id===procedure.id &&
                        <ConsentForm procedure={procedure}/>
                      }
                      </>
                    );
                  })}
                </>
              )}
              <h2 className="mt-6 px-20 flex justify-between text-lg text-text-2">
                <span>
                  Medications&nbsp;&nbsp;
                  <button
                    onClick={() => {
                      const mp = {
                        brand: '',
                        dosage: '',
                        number: '',
                        refill: '',
                        fee: '',
                        Tid: '',
                      };

                      setTreatmentPlan({
                        medications: treatmentPlan.medications.push(mp),
                        ...treatmentPlan,
                      });
                    }}
                  >
                    +
                  </button>
                </span>
              </h2>
              {treatmentPlan.medications.length > 0 && (
                <>
                  <div className="mt-4 grid grid-cols-12 justify-items-center font-font-2 font-normal text-sm">
                    <h2 className="px-2">Priority</h2>
                    <div></div>
                    <h2 className="px-2">Brand</h2>
                    <h2 className="px-2">Dosage</h2>
                    <h2 className="px-2">#</h2>
                    <h2 className="px-2">Refill</h2>
                    <h2 className="px-2">Fee</h2>
                    <div></div>
                    <h2 className="px-2">Consent</h2>
                    <h2 className="px-2">Pay</h2>
                    <h2 className="px-2">Rx</h2>
                  </div>
                  {treatmentPlan.medications.map((medication, index) => {
                    return (
                      <ViewMedication
                        key={index}
                        index={index}
                        medication={medication}
                        changePriority={changePriority}
                        saveTreatment={saveTreatment}
                        deleteTreatment={deleteTreatment}
                        setShowConsent={setShowConsent}
                        has_consent={hasConsent}
                        templateMedications={medicationTemplates}
                        setMedication={data => {
                          let medication = treatmentPlan.medications;
                          medication[index] = data;
                          setTreatmentPlan({
                            medications: medication,
                            ...treatmentPlan,
                          });
                        }}
                        duplicateTreatment={duplicate}
                        setModalConsent={setModalConsent}
                      />
                    );
                  })}
                </>
              )}
              <h2 className="mt-6 px-20 flex justify-between text-lg text-text-2">
                <span>
                  Products&nbsp;&nbsp;
                  <button
                    onClick={() => {
                      const pp = {
                        brand: '',
                        volume: '',
                        number: '',
                        refill: '',
                        fee: '',
                        delivery: '',
                        Tid: '',
                      };
                      setTreatmentPlan({
                        products: treatmentPlan.products.push(pp),
                        ...treatmentPlan,
                      });
                    }}
                    
                  >
                    +
                  </button>
                </span>
              </h2>
              {treatmentPlan.products.length > 0 && (
                <>
                  {/* <div className="mt-4 grid grid-cols-12 justify-items-center font-font-2 font-normal text-sm">
                    <h2 className="px-2">Priority</h2>
                    <div></div>
                    <h2 className="px-2">Brand</h2>
                    <h2 className="px-2">Volume</h2>
                    <h2 className="px-2">#</h2>
                    <h2 className="px-2">Refill</h2>
                    <h2 className="px-2">Fee</h2>
                    <div></div>
                    <h2 className="px-2">Consent</h2>
                    <h2 className="px-2">Pay</h2>
                    <h2 className="px-2">Shipping</h2>
                  </div> */}
                  {treatmentPlan.products.map((product, index) => {
                    return (
                      <>
                      <ViewProduct
                        key={index}
                        index={index}
                        product={product}
                        changePriority={changePriority}
                        saveTreatment={saveTreatment}
                        consent={consent}
                        setConsent={setConsent}
                        deleteTreatment={deleteTreatment}
                        setShowConsent={setShowConsent}
                        setModalConsent={setModalConsent}
                        templateProducts={productTemplates}
                        has_consent={hasConsent}
                        duplicateTreatment={duplicate}
                        setProduct={data => {
                          let product = treatmentPlan.products;
                          product[index] = data;
                          setTreatmentPlan({
                            products: product,
                            ...treatmentPlan,
                          });
                        }}
                        getTreatmentPlans={getTreatmentPlans} 
                        openInfo={openInfo}
                        setOpenInfo={setOpenInfo}
                        openProductNotes={openProductNotes}
                        setOpenProductNotes={setOpenProductNotes}
                      />
                        
                      {
                        openInfo.open && openInfo.id===product.id &&
                        <InfoForm product={product}  />
                      }

                      {
                        openProductNotes.open && openProductNotes.id===product.id &&
                        <ClinicalNotes procedure={product} fetchTreatmentPlan={fetchTreatmentPlan}  getTreatmentPlans={getTreatmentPlans} />
                      }
                       {
                        consent.open && consent.id===product.id &&
                        <ProductConsentForm product={product}/>
                      }
                      </>
                    );
                  })}
                </>
              )}
              {/* <>
              <div className="mx-auto mt-16 text-text-2">
                <h3 className="border-b-2 border-text-1">
                  Total Fees: $
                  {Number(getTotalFee()).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </h3>
              </div>
              <button
                className="mt-10 mx-auto bg-background-15 text-white font-bold focus:outline-none"
                style={{
                  width: '187px',
                  height: '33px',
                  borderRadius: '6px',
                }}
                onClick={() => {
                  addTreatmentPlan();
                }}
              >
                Save
              </button>
              </> */}
            </div>
          ) : (
            <h2 className="mt-8 text-center">No Treatment Plans Yet</h2>
          )}
        </section>
      </main>
      {ready && showConsent && (
        <>
          <ConsentModal
            consent={modalConsent}
            setShowConsent={setShowConsent}
          />
          {/* <button
            className="absolute w-full inset-0 cursor-default"
            onClick={() => setShowConsent(false)}
          ></button> */}
          {/* <article className="fixed z-10 inset-x-0 container mx-auto py-4 px-8 bg-background-10 text-black tracking-tight font-normal" style={{width: '600px', height: '400px', top: '10%'}}>
            <h2 className="text-xl text-center">Consent Form</h2>
            <textarea
              className="mt-4 w-full bg-transparent focus:outline-none"
              rows="5"
              value={treatmentPlan?.consentForm}
              style={{
                backgroundImage:
                  "repeating-linear-gradient(#1a2d3ea9, #1a2d3ea9 30px, 30px,  rgb(154 169 182 / 66%) 32px, #1a2d3ea9 31px)",
                backgroundAttachment: "local",
                fontSize: "1.3rem",
                padding: "4px",
              }}
              onChange={(event) => {
                setTreatmentPlan({
                  ...treatmentPlan,
                  consentForm: event.target.value,
                });
              }}
            ></textarea>
            <div className="mt-8 flex justify-end text-sm">
              <label className="ml-6 flex flex-col">
                <p>Signature:</p>
                <input
                  className="mt-1 w-40 bg-background-2 px-2 py-1 focus:outline-none"
                  type="text"
                  value={treatmentPlan?.signature}
                  onChange={(event) => {
                    setTreatmentPlan({
                      ...treatmentPlan,
                      signature: event.target.value,
                    });
                  }}
                />
              </label>
              <label className="ml-6 flex flex-col">
                <p>Date: </p>
                <input
                  className="mt-1 w-40 bg-background-2 px-2 py-1 focus:outline-none"
                  type="date"
                  value={dayjs(treatmentPlan?.date).format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setTreatmentPlan({
                      ...treatmentPlan,
                      date: event.target.value,
                    });
                  }}
                />
              </label>
            </div>
            <div className="mt-5 flex">
              <button
                className="mx-auto bg-background-14 border border-current focus:outline-none"
                style={{
                  width: "187px",
                  height: "33px",
                  borderRadius: "30px",
                }}
                onClick={() => setShowConsent(false)}
              >
                Cancel
              </button>
              <button
                className="mx-auto bg-background-13 text-white focus:outline-none disabled:opacity-50 disabled:bg-gray-400"
                style={{
                  width: "187px",
                  height: "33px",
                  borderRadius: "30px",
                }}
                disabled={!(treatmentPlan && treatmentPlan.consentForm && treatmentPlan.signature && treatmentPlan.date)}
                onClick={() => setShowConsent(false)}
              >
                Confirm
              </button>
            </div>
          </article> */}
        </>
      )}
      <Loading message={'Loading Treatment Plan'} ready={ready} />
    </>
  );
}
