import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import Subscription from '../pages/subscription/Subscription';
import Loading from '../views/Loading';

export default function Signup(props) {
    const {email_url, type, status} = useParams();
    const [clinicName, setClinicName] = useState('');
    const [email, setEmail] = useState(email_url ? email_url : "");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [ready, setReady] = useState(true);
    const [error, setError] = useState([]);
    const [activeScreen, setActiveScreen] = useState('signup');
    const history = useHistory();


    const signUp = () => {
        // if (isSignUpValid()) {
          setMessage("");
          setError([]);
          setReady(false);
          fetch(`${process.env.REACT_APP_API}/clinic/onboarding`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: clinicName.trim(),
              email: email.trim().toLowerCase(),
              // password: password,
            }),
          })
            .then(response => response.json())
            .then(response => {
              if (response.success) {
                localStorage.setItem('email', response.data.email);
                localStorage.setItem('temp_user_id', response.data.id);
                setActiveScreen("payment_init");
                setReady(true);
                // initCheckout('setup');
              } else {
                setMessage(response.message);
                setError(response.error);
                setReady(true);
              }
            })
            .catch(error => {
              console.error(error);
              setMessage('Some Error Occured. Please Try Again Later');
              setReady(true);
            });
        // }
    };

    const initCheckout = async (type_temp) => {
      // if (isSignUpValid()) {
        setMessage("");
        setError([]);
        setReady(false);
        await fetch(`${process.env.REACT_APP_API}/clinic/onboarding/init_checkout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: type_temp,
            email: email.trim().toLowerCase(),
          }),
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              window.location.href = response.data.url;
            } else {
              if(response.payment_status){
                if(type_temp === 'setup'){ 
                  // setActiveScreen('subscription');
                  setActiveScreen("payment_success");
                  setReady(true);
                }
                // else if(type_temp === "monthly" || type_temp === "annually"){
                //   setActiveScreen("payment_success");
                //   setReady(true);
                // }
              }else{
                setActiveScreen('stripeCheckout');
                setMessage(response.message);
                setError(response.error);
                setReady(true);
              }
            }
          })
          .catch(error => {
            console.error(error);
            setMessage('Some Error Occured. Please Try Again Later');
            setReady(true);
          });
      // }
    };

    const checkPayment = () => {
      setMessage("");
        setError([]);
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/onboarding/payment_status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: type,
            email: email.trim().toLowerCase(),
            // password: password,
          }),
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              if(response.payment_status){
                if(type === "setup"){
                  setActiveScreen('payment_success');
                }
                // else{
                //   setActiveScreen('payment_success');
                // }
              }else{
                setActiveScreen('stripeCheckout');
                setMessage("Your payment is not successful. Please try again later.");
              }
            } else {
              setActiveScreen('stripeCheckout');
              setMessage(response.message);
              setError(response.error);
            }
            window.history.pushState({}, null, "/onboarding");
            setReady(true);
          })
          .catch(error => {
            console.error(error);
            setMessage('Some Error Occured. Please Try Again Later');
            setReady(true);
          });
    }

    const isSignUpValid = () => {
      if(password === confirmPassword){
        return true;
      }
      setError({confirmPassword: "Password does not match confirm password"});
      return false;
    };

    const setupPassword = () => {
      if (isSignUpValid()) {
        setMessage("");
        setError([]);
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/onboarding/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            password: password,
            email: email.trim().toLowerCase(),
            // password: password,
          }),
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              localStorage.setItem('access_token', response.access_token);
              localStorage.setItem('expires_at', (response.expires_at * 1000));
              props.setLoggedIn(true);
              history.push("/onboarding/user-agreement");
            } else {
              setMessage(response.message);
              setError(response.error);
              setReady(true);
            }
          })
          .catch(error => {
            console.error(error);
            setMessage('Some Error Occured. Please Try Again Later');
            setReady(true);
          });
      }
    };

    useEffect(() => {
      if(email_url && type && status) {
        checkPayment();
      } else {
        setMessage('');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
          <main className={`flex flex-col p-4 ${ready ? '' : 'hidden'}`}>
            {
              activeScreen === "signup" && 
              <div className="my-auto flex flex-col">
                  <NavLink to="/" className="mx-auto mb-12">
                      <img className="h-32" src={Logo} alt="GRO Track" />
                  </NavLink>
                  {message && <p className="text-center text-red-600">{message}</p>}
                  <form
                      className="mt-4 flex flex-col items-center"
                      onSubmit={event => {
                        event.preventDefault();
                        signUp();
                      }}
                  >
                      <input
                        type="text"
                        className="px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                        placeholder="clinic name"
                        value={clinicName}
                        required
                        onChange={event => {
                          setClinicName(event.target.value);
                        }}
                      />
                      {error.name && <p className="text-center text-red-600">{error.name}</p>}
                      <input
                        type="email"
                        className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                        placeholder="email"
                        value={email}
                        required
                        onChange={event => {
                          setEmail(event.target.value);
                        }}
                      />
                      {error.email && <p className="text-center text-red-600">{error.email}</p>}
                      
                      <input
                        type="submit"
                        className="mt-8 h-20 w-20 rounded-full bg-accent-1 text-text-3 cursor-pointer focus:outline-none"
                        value="Next"
                      />
                  </form>
                  
              </div>
            }

            {
              activeScreen === "payment_init" && 
              <div className="flex flex-col">
                  <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10 px-auto">
                      <img className="h-20 mx-auto" src={Logo} alt="GRO Track" />
                  </nav>
                  
                  <div className='text-center'>
                    <h1 className='text-2xl text-gray-300 mt-12 mb-6'>GroTrack System</h1>
                    <h1 className='text-2xl text-gray-300 my-6'>$4,500</h1>

                    <div className='flex justify-center'>
                      <ul className='text-left text-gray-300 list-disc list-inside'>
                        <li className='my-6 flex items-center'><div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-6"></div>Grotrack Camera</li>
                        <li className='my-6 flex items-center'><div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-6"></div>Grotrack Software License</li>
                        <li className='my-6 flex items-center'><div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-6"></div>12 Months Service Included</li>
                      </ul>
                    </div>

                    <div className='w-1/3 mx-auto'>
                      <p className='my-12 text-gray-300 '>* a monthly service fee of $199 per month will be charged after the first 12 months. you can cancel grotrack monthly services anytime after the initial 12 months, 30 days prior to the next billing cycle</p>
                      <p className='my-12 text-gray-300 '>* monthly service fee include, software upgrades, unlimited image processing, unlimited could storage, camera warranty, tech support, virtual training.</p>
                    </div>

                    <button
                      className="bg-accent-1 text-white hover:underline focus:outline-none focus:underline px-20 py-2 rounded"
                      onClick={() => {
                        initCheckout('setup')
                        setError("");
                        setMessage(""); 
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                  
              </div>
            }

            {
              activeScreen === "stripeCheckout" && 
              <div className="my-auto flex flex-col">
                  <NavLink to="/" className="mx-auto mb-12">
                      <img className="h-32" src={Logo} alt="GRO Track" />
                  </NavLink>
                  {message && <p className="text-center text-red-600">{message}</p>}
                  <p className="mt-4 text-center">
                      <button
                        className="text-accent-1 hover:underline focus:outline-none focus:underline"
                        onClick={() => {
                          setActiveScreen("signup");
                          setError("");
                          setMessage(""); 
                        }}
                      >
                        Back
                      </button>
                  </p>
              </div>
            }

            {
              activeScreen === "subscription" && 
                  <Subscription initCheckout={initCheckout} />
            }

            {
              activeScreen === "payment_success" && 
              <div className="my-auto flex flex-col">
                  <NavLink to="/" className="mx-auto mb-12">
                      <img className="h-32" src={Logo} alt="GRO Track" />
                  </NavLink>
                  {message && <p className="text-center text-red-600">{message}</p>}
                  
                  <div className='text-center'>
                    <h1 className='text-3xl text-gray-300 my-12'>Thanks for your payment</h1>
                    <h1 className='text-3xl text-gray-300 my-12'>Lets setup your GroTrack account</h1>
                    <button
                      className="bg-accent-1 text-white hover:underline focus:outline-none focus:underline px-20 py-2 rounded"
                      onClick={() => {
                        setActiveScreen("password_setup");
                        setError("");
                        setMessage(""); 
                      }}
                    >
                      START
                    </button>
                  </div>
                  
              </div>
            }

            {
              activeScreen === "password_setup" && 
              <div className="my-auto flex flex-col">
                  <NavLink to="/" className="mx-auto mb-12">
                      <img className="h-32" src={Logo} alt="GRO Track" />
                  </NavLink>
                  {message && <p className="text-center text-red-600">{message}</p>}
                  <form
                      className="mt-4 flex flex-col items-center"
                      onSubmit={event => {
                        event.preventDefault();
                        setupPassword();
                      }}
                  > 
                      <input
                        type="password"
                        className="px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                        placeholder="password"
                        value={password}
                        minLength="8"
                        required
                        onChange={event => {
                          setPassword(event.target.value);
                        }}
                      />
                       {error.password && <p className="text-center text-red-600">{error.password}</p>}
                      <input
                        type="password"
                        className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                        placeholder="confirm password"
                        value={confirmPassword}
                        minLength="8"
                        required
                        onChange={event => {
                          setConfirmPassword(event.target.value);
                        }}
                      />
                      {error.confirmPassword && <p className="text-center text-red-600">{error.confirmPassword}</p>}
                      
                      <input
                        type="submit"
                        className="mt-8 h-20 w-20 rounded-full bg-accent-1 text-text-3 cursor-pointer focus:outline-none"
                        value="Next"
                      />
                  </form>
                  
              </div>
            }
          </main>
          <Loading
            message='Registering Clinic'
            ready={ready}
          />
          <footer className="px-4 pt-4 pb-8 border-t-2 border-accent-2 text-center">
            GRO technologies
          </footer>
        </>
    );
}