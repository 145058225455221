import { React, useState } from "react";
import dayjs from "dayjs";

export default function ConsentModal(props) {
    // const tid = props.consent.tid;
    const [consent, setConsent] = useState(props.consent)
    const saveConsent = () => {
        console.log(consent);
        fetch(
            `${process.env.REACT_APP_API}/clinic/saveConsent`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: JSON.stringify(consent),
            }
        ).then(response => response.json()).then(response => {
          if (response.success) {
            window.location.reload();
          } else {
            alert('Error! ' + response.message);
          }
        })
    }
    return (
        <article className="fixed z-10 inset-x-0 container mx-auto py-4 px-8 bg-background-10 text-black tracking-tight font-normal" style={{width: '600px', height: '400px', top: '10%'}}>
            <h2 className="text-xl text-center">Consent Form</h2>
            <textarea
              className="mt-4 w-full bg-transparent focus:outline-none"
              rows="5"
              value={consent?.consentForm}
              style={{
                backgroundImage:
                  "repeating-linear-gradient(#1a2d3ea9, #1a2d3ea9 30px, 30px,  rgb(154 169 182 / 66%) 32px, #1a2d3ea9 31px)",
                backgroundAttachment: "local",
                fontSize: "1.3rem",
                padding: "4px",
              }}
              onChange={(event) => {
                setConsent({
                  ...consent,
                  consentForm: event.target.value,
                });
              }}
            ></textarea>
            <div className="mt-8 flex justify-end text-sm">
              <label className="ml-6 flex flex-col">
                <p>Signature:</p>
                <input
                  className="mt-1 w-40 bg-background-2 px-2 py-1 focus:outline-none"
                  type="text"
                  value={consent?.signature}
                  onChange={(event) => {
                    setConsent({
                        ...consent,
                      signature: event.target.value,
                    });
                  }}
                />
              </label>
              <label className="ml-6 flex flex-col">
                <p>Date: </p>
                <input
                  className="mt-1 w-40 bg-background-2 px-2 py-1 focus:outline-none"
                  type="date"
                  value={dayjs(consent?.date).format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setConsent({
                        ...consent,
                      date: dayjs(event.target.value).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </label>
            </div>
            <div className="mt-5 flex">
              <button
                className="mx-auto bg-background-14 border border-current focus:outline-none"
                style={{
                  width: "187px",
                  height: "33px",
                  borderRadius: "30px",
                }}
                onClick={() => props.setShowConsent(false)}
              >
                Cancel
              </button>
              <button
                className="mx-auto bg-background-13 text-white focus:outline-none disabled:opacity-50 disabled:bg-gray-400"
                style={{
                  width: "187px",
                  height: "33px",
                  borderRadius: "30px",
                }}
                disabled={!(consent && consent.consentForm && consent.signature && consent.date)}
                onClick={() => saveConsent()}
              >
                Confirm
              </button>
            </div>
        </article>
    );
}