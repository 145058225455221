import React, { useState } from "react";
import { useParams } from "react-router-dom";
//import styles from './ProtocolForm.module.css'
import Loading from "../views/Loading";

function ProtocolForm({ procedure, setProcedure, getTreatmentPlans }) {
  const [ready, setReady] = useState(true);
  const [edit, setEdit] = useState(false);

  const { id } = useParams();

  const obj = {
    treatment_plan_id: procedure.id,
    number_of_treatments: procedure.number_of_treatments,
    laser_level: procedure.laser_level,
    length: procedure.length,
    protocol: procedure.protocol,
    fee: procedure.fee,
    area: procedure.area,
  };
  const [formData, setFormData] = useState(obj);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const updateTreatment = () => {
    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/patient/${id}/treatment_plan/procedure`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          getTreatmentPlans();
        }
      })
      .catch((error) => {
        console.error(error);
        //setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        setReady(true);
      });
  };

  return (
    <>
      <div
        style={{
          background: "#1a2d3ea9",
          width: "80%",
          margin: "1rem auto",
          padding: "2rem 0 3rem 0",
        }}
      >
        <Loading message={"Loading Treatment Plan"} ready={ready} />
        {ready && (
          <div
            style={{
              width: "42rem",
              boxSizing: "border-box",
              padding: "0 1.5rem",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <div style={{ textAlign: "right" }}>
              {edit ? (
                <button
                  onClick={updateTreatment}
                  style={{
                    background: "#3BACA1",
                    borderRadius: "5px",
                    color: "white",
                    padding: "0.2rem 1rem",
                    fontSize: "0.8rem",
                  }}
                >
                  SAVE
                </button>
              ) : (
                <button
                  onClick={() => setEdit(!edit)}
                  style={{
                    background: "#3BACA1",
                    borderRadius: "5px",
                    color: "white",
                    padding: "0.2rem 1rem",
                    fontSize: "0.8rem",
                  }}
                >
                  EDIT
                </button>
              )}
            </div>

            <div>
              <div style={{ fontSize: "1.5rem" }}>
                {procedure.procedure.title}
              </div>
              <div>{procedure.procedure.sub_title}</div>
            </div>

            <div
              style={{
                width: "85%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "1.5rem auto",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Number Of Treatments</div>
                <input
                  type="text"
                  name="number_of_treatments"
                  value={formData.number_of_treatments}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Impact</div>
                <input
                  type="text"
                  name="number_of_treatments"
                  value={formData.number_of_treatments}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div>

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Laser Level</div>
                <input
                  type="text"
                  name="laser_level"
                  value={formData.laser_level}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Length</div>
                <input
                  type="text"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Power</div>
                <input
                  type="text"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div>

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Protocol</div>
                <input
                  type="text"
                  name="protocol"
                  value={formData.protocol}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Fee</div>
                <input
                  type="text"
                  name="fee"
                  value={formData.fee}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Time</div>
                <input
                  type="text"
                  name="fee"
                  value={formData.fee}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div>

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Treatment Areas</div>
                <input
                  type="text"
                  name="area"
                  value={formData.area}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Package</div>
                <input
                  type="text"
                  name="fee"
                  value={formData.fee}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexBasis: "45%",
                  margin: "0.5rem 0",
                }}
              >
                <div style={{ textAlign: "right" }}>Zone</div>
                <input
                  type="text"
                  name="fee"
                  value={formData.fee}
                  onChange={handleChange}
                  disabled={!edit}
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    outline: "none",
                    border: "none",
                    padding: "0.2rem 0.5rem",
                    background: "rgba(246, 247, 250, 0.5)",
                    textAlign: "center",
                    color: "black",
                    fontWeight: "bold",
                  }}
                />
              </div>
            </div>
            {/* <div style={{textAlign:'center',marginTop:'5rem'}}>
                  <button style={{marginRight:'4rem',background:'#3BACA1',borderRadius:'5px',color:'white',padding:'0.2rem 1rem'}}>Add to GRO plan</button>
                  <button style={{background:'#3BACA1',borderRadius:'5px',color:'white',padding:'0.2rem 1rem'}}>Purchase Now</button>
              </div> */}
          </div>
        )}
      </div>
    </>
  );
}

export default ProtocolForm;
