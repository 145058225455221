import { React, useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';

export default function PatientMakePayment() {
  const stripe = useStripe();
  const elements = useElements();
  const params = useParams();
  const id = params.id;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [treatmentPlan, setTreatmentPlan] = useState(null);
  const [payPlans, setPayPlans] = useState({});
  const [card, setCard] = useState({
    cardNumber: '',
    expires: '',
    cvv: '',
    cardHolderFirstName: '',
    cardHolderLastName: '',
    billingAddress: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    phone: '',
    email: '',
  });
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [fee, setFee] = useState(0);
  const [patientReferrals, setPatientReferrals] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/unpaid-treatments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setTreatmentPlan({ ...response });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    if (treatmentPlan) {
      let sto = {};
      treatmentPlan.procedures.forEach(prc => (sto[prc.id] = false));
      treatmentPlan.medications.forEach(med => (sto[med.id] = false));
      treatmentPlan.products.forEach(pdt => (sto[pdt.id] = false));
      setPayPlans({ ...sto });
    }
  }, [treatmentPlan]);

  useEffect(() => {
    if (treatmentPlan && payPlans) {
      let newFee = 0;

      treatmentPlan.procedures.forEach(
        prc => (newFee += payPlans[prc.id] ? Number(prc.fee) : 0)
      );
      treatmentPlan.medications.forEach(
        med => (newFee += payPlans[med.id] ? Number(med.fee) : 0)
      );
      treatmentPlan.products.forEach(
        pdt => (newFee += payPlans[pdt.id] ? Number(pdt.fee) : 0)
      );
      setFee(newFee);
    }
  }, [treatmentPlan, payPlans]);

  const handleTogglePlanPay = id => {
    setPayPlans(prevPlans => ({
      ...prevPlans,
      [id]: !payPlans[id],
    }));
  };

  // const createPayment = () => {
  //   fetch(`${process.env.REACT_APP_API}/clinic/init_add_payment_method`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.success) {
  //         setClientSecret(response.intent.client_secret);
  //         const cardElement = elements.getElement(CardElement);
  //         stripe
  //           .confirmCardSetup(clientSecret, {
  //             type: "card",
  //             card: cardElement,
  //             billing_details: {
  //               address: {
  //                 city: card.billingAddress.city,
  //                 line1: card.billingAddress.street,
  //                 postal_code: card.billingAddress.zip,
  //                 state: card.billingAddress.state,
  //               },
  //               email: card.email,
  //               name: card.cardHolderFirstName + " " + card.cardHolderLastName,
  //               phone: card.phone,
  //             },
  //           })
  //           .then((res) => {
  //             console.log(res);
  //             let payPlansIds = [];
  //             Object.keys(payPlans).forEach((plan) => {
  //               if (payPlans[plan]) payPlansIds.push(plan);
  //             });
  //             // Send Res and Ids to Backend
  //             setReady(false);
  //             fetch(
  //               `${process.env.REACT_APP_API}/patient/${id}/create_payment`,
  //               {
  //                 method: "POST",
  //                 headers: {
  //                   "Content-Type": "application/json",
  //                   Authorization: `Bearer ${localStorage.getItem(
  //                     "access_token"
  //                   )}`,
  //                 },
  //                 body: JSON.stringify({
  //                   ids: payPlansIds,
  //                   paymentID: res.paymentMethod.id,
  //                 }),
  //               }
  //             )
  //               .then((response) => response.json())
  //               .then((response) => {
  //                 console.log(response);
  //               })
  //               .catch((err) => {
  //                 console.error(err);
  //                 setMessage("Some error occured. Try again later");
  //               })
  //               .finally(() => setReady(true));
  //           })
  //           .catch((err) => {
  //             console.error(err);
  //             setMessage("Some error occured. Try again later");
  //           })
  //           .finally(() => setReady(true));
  //       } else {
  //         setMessage(response.message);
  //       }
  //     });
  // };

  const createPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardEl = elements.getElement(CardElement);
    const result = await stripe.createToken(cardEl);

    if (result.error) {
      console.log(result.error.message);
    } else {
      const token = result.token;

      let payPlansIds = [];
      Object.keys(payPlans).forEach(plan => {
        if (payPlans[plan]) payPlansIds.push(Number(plan));
      });

      const paymentData = { stripeToken: token.id, ids: payPlansIds };

      setReady(false);

      const response = await fetch(
        `${process.env.REACT_APP_API}/patient/${id}/create_payment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          body: JSON.stringify(paymentData),
        }
      );

      // Return and display the result of the charge.
      const paymentResult = await response.json();
      setCard({
        cardNumber: '',
        expires: '',
        cvv: '',
        cardHolderFirstName: '',
        cardHolderLastName: '',
        billingAddress: {
          street: '',
          city: '',
          state: '',
          zip: '',
        },
        phone: '',
        email: '',
      });
      setMessage(paymentResult.message);
      setReady(true);
    }
  };

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={5} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}

        <section>
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          <div className="mt-2 flex items-center md:justify-center flex-col md:flex-row">
            <NavLink
              exact
              to={`/patient/payment/make-payment/${id}`}
              activeClassName="text-text-2"
            >
              Make Payment
            </NavLink>
            <div className="mx-8 my-2 w-2 h-2 bg-accent-1 rounded-full"></div>
            <NavLink
              exact
              to={`/patient/payment/view-payment-history/${id}`}
              activeClassName="text-text-2"
            >
              View Payment History
            </NavLink>
          </div>
          {treatmentPlan && (
            <div className="mt-4 grid gap-4 xl:grid-cols-2">
              <div
                className="mt-4 bg-background-5 py-4 px-8 flex flex-col"
                style={{ height: 'var(--height-1)', overflowY: 'auto' }}
              >
                <h1 className="text-xl text-text-2 text-center mb-4">
                  Treatment Plan
                </h1>
                {treatmentPlan.procedures.map((procedure, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-4 grid grid-cols-7 text-center text-text-3 font-font-2 font-normal"
                    >
                      <h2 className="px-2 py-1 bg-background-6 font-bold">
                        {procedure.name}
                      </h2>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {procedure.brand}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {procedure.tx}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {procedure.interval}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {procedure.area}
                      </p>
                      <p className="px-2 py-1 w-full bg-background-7">
                        ${Number(procedure.fee).toLocaleString()}
                      </p>
                      <button
                        className="ml-8 focus:outline-none"
                        onClick={() => handleTogglePlanPay(procedure.id)}
                      >
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill={
                              payPlans[procedure.id] ? '#13c3b2' : '#637381'
                            }
                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })}
                {treatmentPlan.medications.map((medication, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-4 grid grid-cols-7 text-text-3 text-center font-font-2 font-normal"
                    >
                      <h2 className="px-2 py-1 bg-background-6 font-bold">
                        {medication.name}
                      </h2>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {medication.brand}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {medication.dosage}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {medication.number}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {medication.refill}
                      </p>
                      <p className="px-2 py-1 w-full bg-background-7">
                        ${Number(medication.fee).toLocaleString()}
                      </p>
                      <button
                        className="ml-8 focus:outline-none"
                        onClick={() => handleTogglePlanPay(medication.id)}
                      >
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill={
                              payPlans[medication.id] ? '#13c3b2' : '#637381'
                            }
                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })}
                {treatmentPlan.products.map((product, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-4 grid grid-cols-7 text-text-3 text-center font-font-2 font-normal"
                    >
                      <h2 className="px-2 py-1 bg-background-6 font-bold">
                        {product.name}
                      </h2>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {product.brand}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {product.volume}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {product.number}
                      </p>
                      <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                        {product.delivery}
                      </p>
                      <p className="px-2 py-1 w-full bg-background-7">
                        ${Number(product.fee).toLocaleString()}
                      </p>
                      <button
                        className="ml-8 focus:outline-none"
                        onClick={() => handleTogglePlanPay(product.id)}
                      >
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill={payPlans[product.id] ? '#13c3b2' : '#637381'}
                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })}
                <div className="mx-auto mt-16 text-text-2">
                  <h3 className="border-b-2 border-text-1">
                    Total Fees: ${fee}
                  </h3>
                  <button
                    disabled={fee <= 0}
                    className="mt-2 px-2 py-1 bg-accent-1 w-full text-text-3 rounded-lg focus:outline-none"
                  >
                    Confirmed
                  </button>
                </div>
              </div>
              <div
                className="mt-4 bg-background-5 py-4 px-16 flex flex-col"
                style={{ height: 'var(--height-1)', overflowY: 'auto' }}
              >
                <h1 className="text-xl text-text-2 text-center">
                  Process Payment
                </h1>
                <form
                  className="mt-4 flex flex-col items-stretch"
                  onSubmit={event => {
                    event.preventDefault();
                    createPayment();
                  }}
                >
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '20px',
                          color: '#bfc5ca',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                  <label className="mt-4">
                    <p className="font-normal">Card Holder Name</p>
                    <div className="mt-2 grid grid-cols-2 gap-8">
                      <input
                        type="text"
                        value={card.cardHolderFirstName}
                        onChange={event =>
                          setCard({
                            ...card,
                            cardHolderFirstName: event.target.value,
                          })
                        }
                        className="px-4 py-1 bg-background-7 font-font-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        placeholder="First Name"
                      />
                      <input
                        type="text"
                        value={card.cardHolderLastName}
                        onChange={event =>
                          setCard({
                            ...card,
                            cardHolderLastName: event.target.value,
                          })
                        }
                        className="px-4 py-1 bg-background-7 font-font-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        placeholder="Last Name"
                      />
                    </div>
                  </label>
                  <label className="mt-4">
                    <p className="font-normal">Billing Address</p>
                    <input
                      type="text"
                      value={card.billingAddress.street}
                      onChange={event =>
                        setCard({
                          ...card,
                          billingAddress: {
                            ...card.billingAddress,
                            street: event.target.value,
                          },
                        })
                      }
                      className="mt-2 w-full px-4 py-1 bg-background-7 font-font-2 font-bold text-text-2 rounded-sm focus:outline-none"
                      placeholder="Street"
                    />
                    <input
                      type="text"
                      value={card.billingAddress.city}
                      onChange={event =>
                        setCard({
                          ...card,
                          billingAddress: {
                            ...card.billingAddress,
                            city: event.target.value,
                          },
                        })
                      }
                      className="mt-4 w-full px-4 py-1 bg-background-7 font-font-2 font-bold text-text-2 rounded-sm focus:outline-none"
                      placeholder="City"
                    />
                    <div className="mt-4 grid grid-cols-2 gap-8">
                      <input
                        type="text"
                        value={card.billingAddress.state}
                        onChange={event =>
                          setCard({
                            ...card,
                            billingAddress: {
                              ...card.billingAddress,
                              state: event.target.value,
                            },
                          })
                        }
                        className="px-4 py-1 bg-background-7 font-font-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        placeholder="State"
                      />
                      <input
                        type="text"
                        value={card.billingAddress.zip}
                        onChange={event =>
                          setCard({
                            ...card,
                            billingAddress: {
                              ...card.billingAddress,
                              zip: event.target.value,
                            },
                          })
                        }
                        className="px-4 py-1 bg-background-7 font-font-2 font-bold text-text-2 rounded-sm focus:outline-none"
                        placeholder="Zip"
                      />
                    </div>
                  </label>
                  <input
                    type="text"
                    value={card.phone}
                    onChange={event =>
                      setCard({ ...card, phone: event.target.value })
                    }
                    className="mt-4 w-full px-4 py-1 bg-background-7 font-font-2 font-bold text-text-2 rounded-sm focus:outline-none"
                    placeholder="Phone"
                  />
                  <input
                    type="text"
                    value={card.email}
                    onChange={event =>
                      setCard({ ...card, email: event.target.value })
                    }
                    className="mt-4 w-full px-4 py-1 bg-background-7 font-font-2 font-bold text-text-2 rounded-sm focus:outline-none"
                    placeholder="Email"
                  />
                  <button
                    disabled={!stripe || fee <= 0}
                    className="mx-auto mt-12 mb-4 px-8 py-1 bg-accent-1 text-text-3 rounded-lg font-bold focus:outline-none"
                  >
                    PAY NOW
                  </button>
                </form>
              </div>
            </div>
          )}
        </section>
      </main>
      <Loading message={'Loading Payment Information'} ready={ready} />
    </>
  );
}
