import { React, useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PatientViewMatch from '../components/PatientViewMatch';

export default function IndividualMatch() {
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState(false);
  const [age, setAge] = useState(false);
  const [race, setRace] = useState(false);
  const [hairLossCondition, setHairLossCondition] = useState(false);
  const [hairLossArea, setHairLossArea] = useState(false);
  const [prpCheck, setPrpCheck] = useState(false);
  const [exosomeCheck, setExosomeCheck] = useState(false);
  const [transplantCheck, setTransplantCheck] = useState(false);
  const [prp, setPrp] = useState({ brand: '', protocol: '' });
  const [exosome, setExosome] = useState({ brand: '', protocol: '' });
  const [transplant, setTransplant] = useState({ area: '', grafts: '' });
  const [criteria, setCriteria] = useState([]);
  const [matchList, setMatchList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const findMatch = () => {
    setCriteria(['Male', 'Age', 'Hair Data', 'PRP', 'Selphyl', '3 Months']);
    setMatchList([
      { id: 16, gender: 'M', age: '45', plan: 'PRP', matchPercentage: '95' },
      { id: 158, gender: 'M', age: '35', plan: 'PRP', matchPercentage: '92' },
      { id: 181, gender: 'F', age: '55', plan: 'PRP', matchPercentage: '87' },
    ]);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
        } else {
          setMessage(response.message);
        }
      })
      .finally(() => {
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={7} />
        <section className="mt-6">
          {message && <p className="text-center text-red-600">{message}</p>}
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          {selectedPatient ? (
            <PatientViewMatch
              selectedPatient={selectedPatient}
              setSelectedPatient={setSelectedPatient}
              setMessage={setMessage}
              setReady={setReady}
            />
          ) : (
            <>
              <div className="mt-2 flex items-center md:justify-center flex-col md:flex-row">
                <NavLink
                  exact
                  to={`/patient/search/individual-match/${id}`}
                  activeClassName="text-text-2"
                >
                  Individual Match
                </NavLink>
                <div className="mx-8 my-2 w-2 h-2 bg-accent-1 rounded-full"></div>
                <NavLink
                  exact
                  to={`/patient/search/group-search/${id}`}
                  activeClassName="text-text-2"
                >
                  Group Search
                </NavLink>
              </div>

              <div className="mt-4 grid gap-4 xl:grid-cols-2">
                <div
                  className="bg-background-5 py-8 px-20 flex flex-col"
                  style={{ height: 'var(--height-1)', overflowY: 'auto' }}
                >
                  <h1 className="text-xl text-center text-text-2">
                    Match Criteria
                  </h1>
                  <form
                    onSubmit={event => {
                      event.preventDefault();
                    }}
                    className="mt-8 mx-2 flex flex-col font-normal font-font-2 text-text-11"
                  >
                    <div className="flex justify-between">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="hidden"
                          onChange={() => {
                            setGender(!gender);
                          }}
                        />
                        <div
                          className={`w-6 h-6 ${
                            gender ? 'bg-background-15' : 'bg-background-14'
                          }`}
                        ></div>
                        <p className="ml-6">Gender</p>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="hidden"
                          onChange={() => {
                            setAge(!age);
                          }}
                        />
                        <div
                          className={`w-6 h-6 ${
                            age ? 'bg-background-15' : 'bg-background-14'
                          }`}
                        ></div>
                        <p className="ml-6">Age</p>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="hidden"
                          onChange={() => {
                            setRace(!race);
                          }}
                        />
                        <div
                          className={`w-6 h-6 ${
                            race ? 'bg-background-15' : 'bg-background-14'
                          }`}
                        ></div>
                        <p className="ml-6">Race</p>
                      </label>
                    </div>
                    <div className="mt-8 pt-8 border-t border-border-1 flex justify-between">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="hidden"
                          onChange={() => {
                            setHairLossCondition(!hairLossCondition);
                          }}
                        />
                        <div
                          className={`w-6 h-6 ${
                            hairLossCondition
                              ? 'bg-background-15'
                              : 'bg-background-14'
                          }`}
                        ></div>
                        <p className="ml-6">Hair Loss Condition</p>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="hidden"
                          onChange={() => {
                            setHairLossArea(!hairLossArea);
                          }}
                        />
                        <div
                          className={`w-6 h-6 ${
                            hairLossArea
                              ? 'bg-background-15'
                              : 'bg-background-14'
                          }`}
                        ></div>
                        <p className="ml-6">Hair Loss Area</p>
                      </label>
                    </div>
                    <div className="mt-8 pt-8 border-t border-border-1 flex flex-col">
                      <label className="mr-auto flex items-center">
                        <input
                          type="checkbox"
                          className="hidden"
                          onChange={() => {
                            setPrpCheck(!prpCheck);
                            setPrp({ brand: '', protocol: '' });
                          }}
                        />
                        <div
                          className={`w-6 h-6 ${
                            prpCheck ? 'bg-background-15' : 'bg-background-14'
                          }`}
                        ></div>
                        <p className="ml-6">PRP</p>
                      </label>
                      <div className="mt-5 grid grid-cols-2 gap-12">
                        <div className="ml-auto flex items-center">
                          <p>Brand</p>
                          <select
                            disabled={!prpCheck}
                            className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                            value={prp.brand}
                            onChange={event => {
                              setPrp({ ...prp, brand: event.target.value });
                            }}
                          >
                            <option value=""></option>
                            <option>Selphyl</option>
                            <option>Eclipse</option>
                          </select>
                        </div>
                        <div className="ml-auto flex items-center">
                          <p>Protocol</p>
                          <select
                            disabled={!prpCheck}
                            className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                            value={prp.protocol}
                            onChange={event => {
                              setPrp({ ...prp, protocol: event.target.value });
                            }}
                          >
                            <option value=""></option>
                            <option>3 months</option>
                            <option>6 months</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 pt-8 border-t border-border-1 flex flex-col">
                      <label className="mr-auto flex items-center">
                        <input
                          type="checkbox"
                          className="hidden"
                          onChange={() => {
                            setExosomeCheck(!exosomeCheck);
                            setExosome({ brand: '', protocol: '' });
                          }}
                        />
                        <div
                          className={`w-6 h-6 ${
                            exosomeCheck
                              ? 'bg-background-15'
                              : 'bg-background-14'
                          }`}
                        ></div>
                        <p className="ml-6">Exosome</p>
                      </label>
                      <div className="mt-5 grid grid-cols-2 gap-12">
                        <div className="ml-auto flex items-center">
                          <p>Brand</p>
                          <select
                            disabled={!exosomeCheck}
                            className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                            value={exosome.brand}
                            onChange={event => {
                              setExosome({
                                ...exosome,
                                brand: event.target.value,
                              });
                            }}
                          >
                            <option value=""></option>
                            <option>Selphyl</option>
                            <option>Eclipse</option>
                          </select>
                        </div>
                        <div className="ml-auto flex items-center">
                          <p>Protocol</p>
                          <select
                            disabled={!exosomeCheck}
                            className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                            value={exosome.protocol}
                            onChange={event => {
                              setExosome({
                                ...exosome,
                                protocol: event.target.value,
                              });
                            }}
                          >
                            <option value=""></option>
                            <option>3 months</option>
                            <option>6 months</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 pt-8 border-t border-border-1 flex flex-col">
                      <label className="mr-auto flex items-center">
                        <input
                          type="checkbox"
                          className="hidden"
                          onChange={() => {
                            setTransplantCheck(!transplantCheck);
                            setTransplant({ area: '', grafts: '' });
                          }}
                        />
                        <div
                          className={`w-6 h-6 ${
                            transplantCheck
                              ? 'bg-background-15'
                              : 'bg-background-14'
                          }`}
                        ></div>
                        <p className="ml-6">Transplant</p>
                      </label>
                      <div className="mt-5 grid grid-cols-2 gap-12">
                        <div className="ml-auto flex items-center">
                          <p>Area</p>
                          <select
                            disabled={!transplantCheck}
                            className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                            value={transplant.area}
                            onChange={event => {
                              setTransplant({
                                ...transplant,
                                area: event.target.value,
                              });
                            }}
                          >
                            <option value=""></option>
                            <option value=""></option>
                            <option>Front</option>
                            <option>Crown</option>
                            <option>Vertex</option>
                            <option>Back</option>
                            <option>Left</option>
                            <option>Right</option>
                          </select>
                        </div>
                        <div className="ml-auto flex items-center">
                          <p>Grafts</p>
                          <select
                            disabled={!transplantCheck}
                            className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                            value={transplant.grafts}
                            onChange={event => {
                              setTransplant({
                                ...transplant,
                                grafts: event.target.value,
                              });
                            }}
                          >
                            <option value=""></option>
                            <option>3 months</option>
                            <option>6 months</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button
                      className="mt-10 mx-auto bg-background-15 text-white font-bold focus:outline-none"
                      style={{
                        width: '187px',
                        height: '33px',
                        borderRadius: '6px',
                      }}
                      onClick={findMatch}
                    >
                      MATCH
                    </button>
                  </form>
                </div>
                <div
                  className="bg-background-5 py-8 px-20 flex flex-col font-normal font-font-2 text-text-11"
                  style={{ height: 'var(--height-1)', overflowY: 'auto' }}
                >
                  <h1 className="font-bold font-font-1 text-xl text-center text-text-2">
                    Match List
                  </h1>
                  {criteria.length > 0 && (
                    <p className="mt-4 pb-8 text-center border-b border-border-1">
                      {criteria.join(' / ')}
                    </p>
                  )}
                  {matchList.length > 0 && (
                    <div className="mt-8 grid gap-8 text-text-13">
                      {matchList.map(patient => (
                        <div
                          key={patient.id}
                          className="flex items-center justify-between"
                        >
                          <div className="w-8 h-8 flex bg-background-14 justify-center items-center">
                            <p className="text-text-12 font-bold text-lg">
                              {patient.id}
                            </p>
                          </div>
                          <p>{{ M: 'Male', F: 'Female' }[patient.gender]}</p>
                          <p>{patient.age} yrs</p>
                          <p>{patient.plan}</p>
                          <p className="text-accent-4">
                            {patient.matchPercentage}% match
                          </p>
                          <button
                            className="focus:outline-none"
                            onClick={() => {
                              setSelectedPatient(patient);
                            }}
                          >
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#3DE2D2"
                                d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                              />
                            </svg>
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </section>
      </main>
      <Loading message={'Loading Search Results'} ready={ready} />
    </>
  );
}
