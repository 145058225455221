import React, { useState, useEffect } from "react";
import PresentationNavbar from "./PresentationNavbar";
// import hairImagesFront from "../assets/images/hairImagesFront.png";
// import hairImagesVertex from "../assets/images/hairImagesVertex.png";
import dayjs from "dayjs";

const HairImages = ({ id, setMessage, setReady, setView }) => {
  const [viewMode, setViewMode] = useState("front");
  const [currentId, setCurrentId] = useState(0);
  const [hairImagesDetails, setHairImagesDetails] = useState([]);

  // useEffect(() => {
  //   if(hairImagesDetails){
  //     setDate(hairImagesDetails[currentId].date);
  //   }
  // }, [currentId,hairImagesDetails]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          var imageDetails = [];
          for (var index = 0; index < response.hair_analysis.length; index++) {
            var temp = {};
            temp["date"] = response.hair_analysis[index].created_at;
            temp["front"] = [];
            temp["crown"] = [];
            temp["vertex"] = [];
            temp["back"] = [];
            temp["left"] = [];
            temp["right"] = [];
            temp["front"].imgSrc = getImagePath(
              response.hair_analysis[index].images.portrait,
              "frontal"
            );
            temp["crown"].imgSrc = getImagePath(
              response.hair_analysis[index].images.portrait,
              "crown"
            );
            temp["vertex"].imgSrc = getImagePath(
              response.hair_analysis[index].images.portrait,
              "vertex"
            );
            temp["left"].imgSrc = getImagePath(
              response.hair_analysis[index].images.portrait,
              "left"
            );
            temp["back"].imgSrc = getImagePath(
              response.hair_analysis[index].images.portrait,
              "back"
            );
            temp["right"].imgSrc = getImagePath(
              response.hair_analysis[index].images.portrait,
              "right"
            );

            temp["front"].hairDensityScore =
              response.hair_analysis[index].groindex.hairDensity.front;
            temp["crown"].hairDensityScore =
              response.hair_analysis[index].groindex.hairDensity.crown;
            temp["vertex"].hairDensityScore =
              response.hair_analysis[index].groindex.hairDensity.vertex;
            temp["left"].hairDensityScore =
              response.hair_analysis[index].groindex.hairDensity.left;
            temp["back"].hairDensityScore =
              response.hair_analysis[index].groindex.hairDensity.back;
            temp["right"].hairDensityScore =
              response.hair_analysis[index].groindex.hairDensity.right;
            imageDetails.push(temp);
          }
          setHairImagesDetails(imageDetails);
          setCurrentId(0);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
      });
  }, [id, setReady, setMessage]);

  const getImagePath = (array, subType) => {
    for (var index = 0; index < array.length; index++) {
      if (array[index].subType === subType) {
        return array[index].image_path;
      }
    }
    return null;
  };

  return (
    <>
      <div
        className="px-4 py-8 bg-background-5 overflow-hidden"
        style={{ height: "var(--height-2)" }}
      >
        <div className="flex items-center justify-center mx-auto">
          <button
            className={`${
              viewMode === "front" && "text-text-3 "
            } text-base font-normal focus:outline-none mx-2 font-font-2`}
            onClick={() => setViewMode("front")}
          >
            Front
          </button>
          <button
            className={`${
              viewMode === "crown" && "text-text-3 "
            } text-base font-normal focus:outline-none mx-2 font-font-2`}
            onClick={() => setViewMode("crown")}
          >
            Crown
          </button>
          <button
            className={`${
              viewMode === "vertex" && "text-text-3 "
            } text-base font-normal focus:outline-none mx-2 font-font-2`}
            onClick={() => setViewMode("vertex")}
          >
            Vertex
          </button>
          <button
            className={`${
              viewMode === "back" && "text-text-3 "
            } text-base font-normal focus:outline-none mx-2 font-font-2`}
            onClick={() => setViewMode("back")}
          >
            Back
          </button>
          <button
            className={`${
              viewMode === "left" && "text-text-3 "
            } text-base font-normal focus:outline-none mx-2 font-font-2`}
            onClick={() => setViewMode("left")}
          >
            Left
          </button>
          <button
            className={`${
              viewMode === "right" && "text-text-3 "
            } text-base font-normal focus:outline-none mx-2 font-font-2`}
            onClick={() => setViewMode("right")}
          >
            Right
          </button>
        </div>
        <div className="flex items-center justify-center">
          <button
            className="text-2xl focus:outline-none"
            onClick={function () {
              if (currentId > 0) {
                setCurrentId(currentId - 1);
              }
            }}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
            >
              <path
                fill="#bfc5ca"
                d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
              />
            </svg>
          </button>
          <div className="grid justify-center flex-grow grid-cols-3 mt-2">
            {(viewMode === "front" ||
              viewMode === "crown" ||
              viewMode === "vertex" ||
              viewMode === "left" ||
              viewMode === "right" ||
              viewMode === "back") && (
              <>
                {hairImagesDetails.map(
                  (hID, idx) =>
                    (idx === currentId - 1 ||
                      idx === currentId ||
                      idx === currentId + 1) && (
                      <div
                        key={idx}
                        className={
                          currentId === 0 && idx === 0 ? "col-start-2" : ""
                        }
                      >
                        <div
                          className="flex flex-col items-center justify-start my-4 p-4"
                          style={{ width: "400px" }}
                        >
                          <div className="mt-4">
                            {hID[viewMode].imgSrc ? (
                              <img
                                src={hID[viewMode].imgSrc}
                                alt="Front Img"
                                style={{ maxWidth: "100%" }}
                                className="h-96"
                              />
                            ) : (
                              "No Image found"
                            )}
                          </div>
                        </div>
                        <h2
                          key={idx}
                          className="flex items-end justify-center pt-4 mt-4 text-xl font-bold leading-5 text-center border-t font-font-2 border-text-1"
                        >
                          {dayjs.unix(hID.date).format("M-D-YYYY")}: GRO{" "}
                          <div
                            className="text-xs ml-2 text-black text-center"
                            style={{ position: "relative" }}
                          >
                            <div
                              className="flex bg-gray-100"
                              style={{
                                backgroundColor: "#F7A79D",
                                height: `${
                                  2 * (hID[viewMode].hairDensityScore / 80)
                                }rem`,
                                position: "absolute",
                                width: "100%",
                                bottom: 0,
                                zIndex: "1",
                              }}
                            ></div>

                            <p
                              className="px-2 mt-auto text-center"
                              style={{
                                zIndex: "1000",
                                position: "relative",
                              }}
                            >
                              {hID[viewMode].hairDensityScore}
                            </p>
                          </div>
                        </h2>
                      </div>
                    )
                )}
              </>
            )}
            {/* {(viewMode === "front" ||
                viewMode === "crown" ||
                viewMode === "vertex" ||
                viewMode === "left" ||
                viewMode === "right" ||
                viewMode === "back") && (
                <>
                  {hairImagesDetails.map((hID, idx) => (
                    <h2
                      key={idx}
                      className="flex items-end justify-center pt-4 mt-4 text-xl font-bold leading-5 text-center border-t font-font-2 border-text-1">
                      {dayjs(hID.date).format("MM-DD-YY")}: GRO{" "}
                      <div
                        className="flex ml-2 text-xs text-black bg-gray-100"
                        style={{
                          backgroundColor: "#F7A79D",
                          height: `${
                            2 * (hID[viewMode].hairDensityScore / 80)
                          }rem`,
                        }}>
                        <p className="px-2 mt-auto">
                          {hID[viewMode].hairDensityScore}
                        </p>
                      </div>
                    </h2>
                  ))}
                </>
              )} */}
          </div>
          <button
            className="text-2xl focus:outline-none"
            onClick={function () {
              if (currentId + 1 < hairImagesDetails.length) {
                setCurrentId(currentId + 1);
              }
            }}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
            >
              <path
                fill=" #bfc5ca"
                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
              />
            </svg>
          </button>
        </div>
      </div>
      <PresentationNavbar tab={2} setView={setView} />
    </>
  );
};

export default HairImages;
