export const calculateGroIndexScore = (number, mainType) => {
	if(mainType === 'closeup'){
		if (number <= 5) {
			return 0.5;
		} else {
			var slabs = Math.floor((Math.ceil(number) - 1) / 5);
			return 0.5 + slabs * 0.5;
		}
	}else{
		if (number <= 10) {
			return 1;
		} else {
			var slabs = Math.floor((Math.ceil(number) - 1) / 10);
			return slabs * 1;
		}
	}
}


export const getHairComparisonPercentage = (baseline, current) => {
    if(baseline > 0){
        const percentage = ((current - baseline) / current * 100).toFixed(0);

        switch (Math.sign(percentage)){
            case 1:
                return  `+${percentage}`;
            case -1:
                return  percentage;
            default:
                return  percentage;
        }
    }
    return 0;
}