import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import dayjs from "dayjs";

export default function Notifications() {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);
  const [physician, setPhysician] = useState(null);
  const [allNotifications, setAllNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [tab, setTab] = useState("unread");

    useEffect(() => {
        setReady(true);
    }, []);

    const getAdminProfile = () => {
        fetch(`${process.env.REACT_APP_API}/admin/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setPhysician(response.adminProfile);
                } else {
                    setMessage(response.message);
                }
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
    }

    const getAllNotifications = () => {
        fetch(`${process.env.REACT_APP_API}/clinic/notification/all`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setAllNotifications(response.data);
                } 
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
    }

    const getUnreadNotifications = () => {
        fetch(`${process.env.REACT_APP_API}/clinic/notification/unread`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setUnreadNotifications(response.data);
                } 
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
    }

    const markAsRead = (notification_id) => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/notification/${notification_id}/read`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setAllNotifications(prev => {
                        return prev.map((r) => {
                            if(r.id === notification_id){
                                r.read_at = true;
                            }
                            return r;
                        })
                    });

                    setUnreadNotifications(prev => {
                        return prev.filter((r) => {
                            return r.id !== notification_id;
                        })
                    });
                } 
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
    }

    useEffect(() => {
        getAdminProfile();
        getAllNotifications();
        getUnreadNotifications();
    }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}
      >
        <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
            <NavLink to="/">
                <img className="h-20" src={Logo} alt="GRO Track" />
            </NavLink>
            <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
                {physician && (physician.clinic.name) }
            </h1>
        </nav>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex">
            <AdminSidebar />
            <div className="w-full p-4">
                <div className="flex px-3">
                    <div className={`${tab === 'unread' ? 'text-white' : ''} cursor-pointer`} onClick={(e) => {setTab('unread')}}>
                        Unread
                    </div>
                    <div className={`${tab === 'all' ? 'text-white' : ''} mx-5 cursor-pointer`} onClick={(e) => {setTab('all')}}>
                        All
                    </div>
                </div>

                <div className="my-3 px-3">
                    {
                        tab === 'unread' && (
                            unreadNotifications.map((notification, index) => {
                                return <Notification notification={notification} key={index} markAsRead={markAsRead} />
                            })
                        )
                    }

                    {
                        tab === 'all' && (
                            allNotifications.map((notification, index) => {
                                return <Notification notification={notification} key={index} markAsRead={markAsRead} />
                            })
                        )
                    }
                </div>
            </div>
        </section>
      </main>
      <Loading message={"Loading  Details"} ready={ready} />
    </>
  );
}

const Notification = ({notification, markAsRead}) => {
    return (
        <div className="flex px-3 py-2 bg-background-5 my-2 justify-center  ">
            <div className="flex-1">
                <NavLink to={`/patient/presentation/overview/${notification.data.patient.id}`} target="_blank" className="hover:text-white">{notification.data.message}</NavLink>
                <div className="flex text-xs align-items-center">
                    <div>
                        {dayjs(notification.created_at).format('MM-D-YYYY hh:mm A')} 
                    </div>
                    <BsDot />
                    <div>
                        {notification.data.patient.firstName}  {notification.data.patient.lastName}  
                    </div>
                </div>
            </div>
            <div className="w-8 flex align-items-center">
                {
                    notification.read_at === null && <AiFillCheckCircle className="cursor-pointer m-auto hover:text-white" onClick={(e) => {markAsRead(notification.id)}}/>
                }
            </div>
        </div>
    )
}
