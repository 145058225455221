import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import Loading from '../views/Loading';

function ProductConsentForm({procedure,getTreatmentPlans,setstate}) {

  // const [ready, setReady] = useState(true);
  const ref = useRef()
  const { id } = useParams();
  const treatment_plan_id = procedure.id
  const consent = procedure.consent

  useEffect(()=>{
    console.log(procedure.consent)
    // console.log(consent)
    if(consent){
      setFormData({
        body: consent.consent_form,
        signature: consent.signature,
        date: dayjs(consent.date).format('MM-D-YYYY')
      })
    }
  },[consent])

  const [formData,setFormData] = useState({
    body: '',
    signature: '',
    date: ''
  })
  const [message,setMessage] = useState(null)

  const handleChange = (e) =>{
    const {name,value} = e.target
    setFormData({
      ...formData,
      [name] : value
    })
  }

  const saveConsent2 = (e) => {
    // setReady(false)
    setstate()
    console.log(formData)
    e.preventDefault()
    fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan/${treatment_plan_id}/consent-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
           fetchDetails() 
         
          console.log("successfull")
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        // setReady(true);
      });
  };
  const fetchDetails = () => {
    getTreatmentPlans()
  }

  return (
    <>
    <div style={{
      background:'#1a2d3ea9',
      width:'80%',
      margin:'1rem auto',
      padding:'2rem 0 3rem 0',
  }}>
    
    <div style={{textAlign:'center'}}>PRP consent form</div>

    <form onSubmit={saveConsent2}>
    <div style={{textAlign:'center'}}>
      <textarea rows={10} name='body' value={formData.body} onChange={handleChange} style={{background:'#51606d',width:'70%',margin:'1.5rem auto',color:'rgb(190, 196, 202)',outline:'none',padding:'0.5rem',boxSizing:'border-box'}}/>
    </div>

    <div style={{display:'flex',alignItems:'center',justifyContent:'space-evenly',width:'70%',margin:'0 auto'}}>
      <input type='text' name='signature' value={formData.signature} onChange={handleChange} placeholder='Signature:' style={{padding:'0.5rem',background:'#51606d',outline:'none',color:'rgb(190, 196, 202)'}} />
      <input type='text' name='date' value={formData.date} onChange={handleChange} ref={ref} onClick={()=>ref.current.type='date'} placeholder='Date:' style={{padding:'0.5rem',background:'#51606d',outline:'none',color:'rgb(190, 196, 202)'}} />
    </div>

    <div style={{textAlign:'center',marginTop:'2rem'}}>
      <button type='submit' style={{width:'fit-content',background:'rgb(19, 196, 179)',color:'white',padding:'0.2rem 2rem',borderRadius:'5px'}}>Add</button>
    </div>
    </form>
    
   
  </div>
   

    </>
  )
}

export default ProductConsentForm