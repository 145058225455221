import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import Logo from "../assets/images/logo.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";

const getFirstName = (name) => {
  if (name.lastIndexOf(" ") === -1) {
    return name;
  }
  return name.substring(0, name.lastIndexOf(" ")).trim();
};

const getLastName = (name) => {
  if (name.lastIndexOf(" ") === -1) {
    return "";
  }
  return name.substring(name.lastIndexOf(" ")).trim();
};

export default function AdminPayment() {
  const stripe = useStripe();
  const elements = useElements();

  const [plans, setPlans] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [canPay, setCanPay] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [card, setCard] = useState(null);
  const [editCard, setEditCard] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);

  const getPlans = async () => {
    await fetch(`${process.env.REACT_APP_API}/clinic/get_plans`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPlans(response.plans);
        } else {
          setMessage(response.message);
        }
      });
  };

  const getSubscriptionStatus = async () => {
    await fetch(`${process.env.REACT_APP_API}/clinic/subscription_status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setSubscriptionStatus({
            status: response.status,
            plan: response.plan,
            subscriptionEndsAt: response.subscription_ends_at,
            trialStatus: response.trial_status,
            trialEndsAt: response.trial_ends_at,
          });
        } else {
          setMessage(response.message);
        }
      });
  };

  const createSubscription = (id) => {
    setReady(false);
    setSuccess(false);
    fetch(`${process.env.REACT_APP_API}/clinic/create_subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        plan_name: id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (response.url) {
            setSuccess(response.message);
            const win = window.open(response.url, "_blank");
            if (win) {
              win.focus();
            }
            setReady(true);
          } else {
            getSubscriptionStatus().then(() => {
              setSuccess(response.message);
              setReady(true);
            });
          }
        } else {
          setMessage(response.message);
          setReady(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  };

  const getPaymentMethods = async () => {
    await fetch(`${process.env.REACT_APP_API}/clinic/get_payment_methods`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (Object.keys(response.payment_method).length > 0) {
            setCanPay(true);
            const payment_method = response.payment_method;
            setCard({
              cardNumber: `···· ···· ···· ${payment_method.card.last4}`,
              expires: `${payment_method.card.exp_month}/${payment_method.card.exp_year}`,
              cvv: "···",
              cardHolderFirstName: getFirstName(
                payment_method.billing_details.name
              ),
              cardHolderLastName: getLastName(
                payment_method.billing_details.name
              ),
              billingAddress: {
                street: payment_method.billing_details.address.line1,
                city: payment_method.billing_details.address.city,
                state: payment_method.billing_details.address.state,
                zip: payment_method.billing_details.address.postal_code,
              },
              phone: payment_method.billing_details.phone,
              email: payment_method.billing_details.email,
            });
            setEditCard({
              cardHolderFirstName: getFirstName(
                payment_method.billing_details.name
              ),
              cardHolderLastName: getLastName(
                payment_method.billing_details.name
              ),
              billingAddress: {
                street: payment_method.billing_details.address.line1,
                city: payment_method.billing_details.address.city,
                state: payment_method.billing_details.address.state,
                zip: payment_method.billing_details.address.postal_code,
              },
              phone: payment_method.billing_details.phone,
              email: payment_method.billing_details.email,
            });
          } else {
            setCanPay(false);
            setCard({
              cardNumber: "",
              expires: "",
              cvv: "",
              cardHolderFirstName: "",
              cardHolderLastName: "",
              billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
              },
              phone: "",
              email: "",
            });
            setEditCard({
              cardHolderFirstName: "",
              cardHolderLastName: "",
              billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
              },
              phone: "",
              email: "",
            });
          }
        } else {
          setMessage(response.message);
        }
      });
  };

  const initAddPaymentMethod = async () => {
    await fetch(`${process.env.REACT_APP_API}/clinic/init_add_payment_method`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setClientSecret(response.intent.client_secret);
        } else {
          setMessage(response.message);
        }
      });
  };

  const isCardValid = () => {
    return true;
  };

  const saveCard = () => {
    setSuccess("");
    if (isCardValid() && stripe && elements) {
      setReady(false);
      const cardElement = elements.getElement(CardElement);
      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              address: {
                city: editCard.billingAddress.city,
                line1: editCard.billingAddress.street,
                postal_code: editCard.billingAddress.zip,
                state: editCard.billingAddress.state,
              },
              email: editCard.email,
              name:
                editCard.cardHolderFirstName +
                " " +
                editCard.cardHolderLastName,
              phone: editCard.phone,
            },
          },
        })
        .then(function (result) {
          if (result.error) {
            setMessage(result.error.message);
            setReady(true);
          } else {
            fetch(`${process.env.REACT_APP_API}/clinic/add_payment_method`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
              body: JSON.stringify({
                payment_method: result.setupIntent.payment_method,
              }),
            })
              .then((response) => response.json())
              .then((response) => {
                if (response.success) {
                  Promise.all([
                    getPaymentMethods(),
                    initAddPaymentMethod(),
                  ]).then(() => {
                    setSuccess(response.message);
                    setEditMode(false);
                    setReady(true);
                  });
                } else {
                  setMessage(response.message);
                  setReady(true);
                }
              })
              .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    }
  };

  useEffect(() => {
    Promise.all([
      getPlans(),
      getSubscriptionStatus(),
      getPaymentMethods(),
      initAddPaymentMethod(),
    ])
      .then(() => {
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}
      >
        <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
          <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
            PACIFIC HAIR CENTER
          </h1>
        </nav>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex">
          <AdminSidebar />
          {card && (
            <div className="mx-auto">
              <form
                className="mt-4 flex flex-col"
                onSubmit={(event) => {
                  event.preventDefault();
                  saveCard();
                }}
              >
                <div className="flex justify-between items-center">
                  <button
                    type="button"
                    className="px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold text-sm
              cursor-pointer focus:outline-none"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  >
                    {editMode ? "Cancel" : "Edit"}
                  </button>
                  <p className="mx-4 text-text-2">PAYMENT INFORMATION</p>
                  <button
                    className="px-8 py-1 bg-accent-1 rounded-lg text-text-3 text-sm
              cursor-pointer focus:outline-none"
                    disabled={!editMode}
                  >
                    SAVE
                  </button>
                </div>
                <div className="mx-auto mt-10 flex flex-col items-end">
                  <div
                    className={`w-full md:w-96 px-2 ${
                      editMode ? "" : "hidden"
                    }`}
                  >
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: "20px",
                            color: "#bfc5ca",
                            "::placeholder": {
                              color: "#aab7c4",
                            },
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        },
                      }}
                    />
                  </div>
                  {!editMode && (
                    <>
                      <label className="mt-4 flex items-center">
                        <p className="ml-2">Card Number</p>
                        <input
                          type="text"
                          className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 font-bold text-text-2 rounded-sm focus:outline-none"
                          value={
                            editMode ? editCard.cardNumber : card.cardNumber
                          }
                          disabled={!editMode}
                          onChange={(event) => {
                            setEditCard({
                              ...editCard,
                              cardNumber: event.target.value,
                            });
                          }}
                        />
                      </label>
                      <div className="mt-4 flex flex-col items-end md:flex-row">
                        <label className="flex items-center">
                          <p className="ml-2">Expires</p>
                          <input
                            type="text"
                            className="ml-6 md:w-46 font-bold w-40 px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                            value={editMode ? editCard.expires : card.expires}
                            disabled
                          />
                        </label>
                        <label className="mt-4 md:mt-0 md:ml-5 flex items-center">
                          <p className="font-normal">CVV</p>
                          <input
                            type="text"
                            className="ml-5 w-40 md:w-32 font-bold px-2 py-1 bg-background-2 text-text-2 rounded-sm focus:outline-none"
                            value={editMode ? editCard.cvv : card.cvv}
                            disabled
                          />
                        </label>
                      </div>
                    </>
                  )}
                  <div className="mt-4 flex">
                    <p className="ml-2 mt-1">Card Holder Name</p>
                    <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                      <label className="flex items-center">
                        <input
                          type="text"
                          className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                          value={
                            editMode
                              ? editCard.cardHolderFirstName
                              : card.cardHolderFirstName
                          }
                          disabled={!editMode}
                          placeholder="First Name"
                          required
                          onChange={(event) => {
                            setEditCard({
                              ...editCard,
                              cardHolderFirstName: event.target.value,
                            });
                          }}
                        />
                      </label>
                      <label className="flex items-center">
                        <input
                          type="text"
                          className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                          value={
                            editMode
                              ? editCard.cardHolderLastName
                              : card.cardHolderLastName
                          }
                          disabled={!editMode}
                          placeholder="Last Name"
                          required
                          onChange={(event) => {
                            setEditCard({
                              ...editCard,
                              cardHolderLastName: event.target.value,
                            });
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">Billing Address</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        editMode
                          ? editCard.billingAddress.street
                          : card.billingAddress.street
                      }
                      disabled={!editMode}
                      placeholder="Street"
                      required
                      onChange={(event) => {
                        setEditCard({
                          ...editCard,
                          billingAddress: {
                            ...editCard.billingAddress,
                            street: event.target.value,
                          },
                        });
                      }}
                    />
                  </label>
                  <input
                    type="text"
                    className="mt-4 ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={
                      editMode
                        ? editCard.billingAddress.city
                        : card.billingAddress.city
                    }
                    disabled={!editMode}
                    placeholder="City"
                    required
                    onChange={(event) => {
                      setEditCard({
                        ...editCard,
                        billingAddress: {
                          ...editCard.billingAddress,
                          city: event.target.value,
                        },
                      });
                    }}
                  />
                  <div className="mt-4 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 font-bold bg-background-2 text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editCard.billingAddress.state
                            : card.billingAddress.state
                        }
                        disabled={!editMode}
                        placeholder="State"
                        required
                        onChange={(event) => {
                          setEditCard({
                            ...editCard,
                            billingAddress: {
                              ...editCard.billingAddress,
                              state: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editCard.billingAddress.zip
                            : card.billingAddress.zip
                        }
                        disabled={!editMode}
                        placeholder="Zip"
                        required
                        onChange={(event) => {
                          setEditCard({
                            ...editCard,
                            billingAddress: {
                              ...editCard.billingAddress,
                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </div>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">PHONE</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editMode ? editCard.phone : card.phone}
                      disabled={!editMode}
                      required
                      onChange={(event) => {
                        setEditCard({
                          ...editCard,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">EMAIL</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editMode ? editCard.email : card.email}
                      disabled={!editMode}
                      required
                      onChange={(event) => {
                        setEditCard({
                          ...editCard,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                </div>
              </form>
              {subscriptionStatus && (
                <div className="mt-16 flex flex-col items-center">
                  <p className="text-text-2">SUBSCRIPTION STATUS</p>
                  {subscriptionStatus.status ? (
                    <p className="mt-4">
                      <strong>Current Plan : </strong>
                      {subscriptionStatus.plan}
                      {subscriptionStatus.subscriptionEndsAt &&
                        `, ends on ${dayjs().format("MMMM D, YYYY")}`}
                    </p>
                  ) : (
                    <p className="mt-4">
                      {subscriptionStatus.trialStatus ? (
                        <>
                          <strong>Trial Period Ends on : </strong>
                          {dayjs(subscriptionStatus.trialEndsAt).format(
                            "MMMM D, YYYY"
                          )}
                        </>
                      ) : (
                        <strong>Trial Period Ended</strong>
                      )}
                    </p>
                  )}
                </div>
              )}
              {plans.length > 0 && (
                <div className="mt-16 flex flex-col items-center">
                  <p className="text-text-2">AVAILABLE PLANS</p>
                  <ul className="mt-4 grid gap-2">
                    {plans.map((plan) => {
                      return (
                        <li key={plan.id} className="flex items-center">
                          <p>
                            {plan.product_details.name.charAt(0).toUpperCase() +
                              plan.product_details.name.slice(1)}
                          </p>
                          <button
                            disabled={!canPay}
                            className="ml-4 px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold
            cursor-pointer focus:outline-none"
                            onClick={() => {
                              createSubscription(plan.id);
                            }}
                          >
                            {plan.currency.toUpperCase() + " " + plan.amount}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <form
                className="mt-16 flex flex-col"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <div className="flex items-center">
                  <p className="mx-auto text-text-2">USER NAME & PASSWORD</p>
                  <button
                    className="px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold
            cursor-pointer focus:outline-none"
                  >
                    RESET
                  </button>
                </div>
                <div className="mx-auto mt-6 flex flex-col items-end bg-background-5 px-4 lg:px-16 py-8">
                  <label className="flex items-center">
                    <p className="ml-2 font-normal">USER NAME</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={username}
                      required
                      onChange={(event) => {
                        setUsername(event.target.value);
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2 font-normal">PASSWORD</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={password}
                      required
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                    />
                  </label>
                </div>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading message={"Loading Payment Details"} ready={ready} />
    </>
  );
}
