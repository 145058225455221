import React, { useState } from 'react'
import styles from './Subscription.module.css'
import Logo from '../../assets/images/logo.png'

const Subscription = ({ initCheckout }) => {
    const submitForm = (e) => {
        e.preventDefault()
        initCheckout(subscriptionType);
    }
    const [subscriptionType, setSubscriptionType] = useState("");

    return (
        <div>
            <div className={styles.topContainer}>
                <img src={Logo} alt="logo" className={styles.logo}/>
            </div>

            <form onSubmit={submitForm} className={styles.form}>
                <div>MONTHLY SERVICE FEE PAYMENT AUTHORIZATION</div>

                <div className={styles.optionContainer}>
                    <div className={styles.option}>
                        <input style={{marginTop:'6px'}} type="radio" id='pay annually' name='subscription' checked={subscriptionType === 'annually' ? true : false} onChange={(e) => { setSubscriptionType("annually")}} />
                        <div className={styles.optionContent}>
                            pay annually: <br/>
                            $149 per month (save 25%) <br/>
                            total payment $1,788 per year
                        </div>
                    </div>
                    <div className={styles.option}>
                        <input style={{marginTop:'6px'}} type="radio" id='pay monthly' name='subscription' checked={subscriptionType === 'monthly' ? true : false} onChange={(e) => { setSubscriptionType("monthly")}} />
                        <div className={styles.optionContent}>
                            pay monthly: <br/>
                            $199 per month
                            <div style={{color:'#637381'}}>* a minimum of 6 months service required</div>
                        </div>
                    </div>
                </div>

                <div className={styles.message}>
                    by clicking "confirm" you authorize gro technologies to charge <br/>
                    monthly service fee on your credit card or debit card monthly or annually <br/>
                    (based on the payment option you selected) for the duration your account is active
                </div>

                <button type="submit" className={styles.formButton}>CONFIRM</button>
            </form>
        </div>
    )
}

export default Subscription