import React, { useState } from "react";
import { useParams } from "react-router-dom";

function ProcedureForm({ item, openGroPlan, setOpenGroPlan, getTreatmentPlans, matchProcedure}) {
  const { id } = useParams();

  const obj = {
    treatment_procedure_id: item.id,
    number_of_treatments: "",
    laser_level: "",
    length: "",
    protocol: "",
    fee: "",
    area: "",
  };
  const [formData, setFormData] = useState(obj);
  const [disabled,setDisabled] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitForm = () => {
    console.log(formData);
    fetch(
      `${process.env.REACT_APP_API}/patient/${id}/treatment_plan/procedure`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          console.log("Added");
          //setOpenGroPlan(!openGroPlan)
          getTreatmentPlans()
          setDisabled(!disabled)
        }
      })
      .catch((error) => {
        console.error(error);
        //setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        //setReady(true);
      });
  };

  return (
    <div
      className="my-8 w-full col-span-2"
      style={{
        backgroundColor: "rgba(246, 247, 250, 0.9)",
        boxSizing: "border-box",
        padding: "2rem 1.5rem",
        textAlign: "center",
      }}
    >
      <div style={{ color: "black" }}>
        <div className="mt-12 text-center font-bold text-2xl leading-15 font-arial tracking-wider text-gray-800 opacity-100">{item.title}</div>
        <div className="text-center font-normal text-xl leading-15 font-arial tracking-wider text-gray-800 opacity-100">{item.sub_title}</div>
      </div>

      <div
        style={{
          width: "85%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1.5rem auto",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "0.5rem 0",
          }}
        >
          <div className="text-right font-bold text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80">
            Number Of Treatments
          </div>
          <input
            type="text"
            name="number_of_treatments"
            value={formData.number_of_treatments}
            onChange={handleChange}
            className="text-center font-normal text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80"
            style={{
              width: "8rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "0.5rem 0",
          }}
        >
          <div className="text-right font-bold text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80">
            Power Level
          </div>
          <input
            type="text"
            name="laser_level"
            value={formData.laser_level}
            onChange={handleChange}
            className="text-center font-normal text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80"
            style={{
              width: "8rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "0.5rem 0",
          }}
        >
          <div className="text-right font-bold text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80">
            Frequency
          </div>
          <input
            type="text"
            name="length"
            value={formData.length}
            onChange={handleChange}
            className="text-center font-normal text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80"
            style={{
              width: "8rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "0.5rem 0",
          }}
        >
          <div className="text-right font-bold text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80">
            Protocol
          </div>
          <input
            type="text"
            name="protocol"
            value={formData.protocol}
            onChange={handleChange}
            className="text-center font-normal text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80"
            style={{
              width: "8rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "0.5rem 0",
          }}
        >
          <div className="text-right font-bold text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80">
            Fee
          </div>
          <input
            type="text"
            name="fee"
            value={formData.fee}
            onChange={handleChange}
            className="text-center font-normal text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80"
            style={{
              width: "8rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "0.5rem 0",
          }}
        >
          <div className="text-right font-bold text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80">
            Treatment Areas
          </div>
          <input
            type="text"
            name="area"
            value={formData.area}
            onChange={handleChange}
            className="text-center font-normal text-16 leading-21 font-roboto tracking-wider text-gray-900 capitalize opacity-80"
            style={{
              width: "8rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
            }}
          />
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "5rem" }}>
        <button
          onClick={submitForm}
          disabled={disabled}
          className="py-2 px-8 font-normal text-sm leading-21 font-roboto tracking-wider text-white opacity-100 mx-10"
          style={{ backgroundColor: '#1B9DBE' }}
        >
          {disabled ? "Added to GRO plan" : "Add to GRO plan"}
        </button>
        <button
          className="py-2 px-8 font-normal text-sm leading-21 font-roboto tracking-wider text-white opacity-100 mx-10"
          style={{ backgroundColor: '#1B9DBE' }}
        >
          Pay Now
        </button>
      </div>
    </div>
  );
}

export default ProcedureForm;
