
export const hairThinningUiData = {
	healthy: {
		levelBgColor: "",
		markerBgColor: "#1b9dbe",
		markerText: "Healthy",
		markerKey: 'Normal',
		romanValue: "",
		tooltipHeading: "",
		tolltipDescription: ""
	},
	classOne: {
		levelBgColor: "#ffffff",
		markerBgColor: "#ef8679",
		markerText: "CLASS",
		markerKey: 'Early Thinning',
		romanValue: "I",
		tooltipHeading: "Early Thinning",
		tolltipDescription: "(10-20% hair loss)"

	},
	classTwo: {
		levelBgColor: "#fcf1f0",
		markerBgColor: "#ef8679",
		markerText: "CLASS",
		markerKey: 'Moderate Thinning',
		romanValue: "II",
		tooltipHeading: "Moderate Thinning",
		tolltipDescription: "(21-40% hair loss)"
	},
	classThree: {
		levelBgColor: "#fadcd8",
		markerBgColor: "#f56969",
		markerText: "CLASS",
		markerKey: 'Visible Thinning',
		romanValue: "III",
		tooltipHeading: "Visible Thinning",
		tolltipDescription: "(41-60% hair loss)"

	},
	classFour: {
		levelBgColor: "#f0beb9",
		markerBgColor: "#d5481e",
		markerText: "CLASS",
		markerKey: 'Advanced Thinning',
		romanValue: "IV",
		tooltipHeading: "Advanced Thinning",
		tolltipDescription: "(61-75% hair loss)"

	},
	classFive: {
		levelBgColor: "#f7a79d",
		markerBgColor: "#eb3636",
		markerText: "CLASS",
		markerKey: 'Severe Thinning',
		romanValue: "V",
		tooltipHeading: "Severe Thinning",
		tolltipDescription: "(>75% hair loss)"
	}
}



export const hairGrowthUiData = {
	growthLevelData: {
		healthy: {
			levelBgColor: "#4dbfdb",

		},
		classOne: {
			levelBgColor: "#5ecae5",

		},
		classTwo: {
			levelBgColor: "#7cd7ee",

		},
		classThree: {
			levelBgColor: "#aee7f5",

		},
		classFour: {
			levelBgColor: "#f7a79d",

		},
		classFive: {
			levelBgColor: "#ef8679",

		}
	},
	markerPositions: {
		"10": {
			position: "-6.6%",
			text: "10",
			markerBgColor: "#1b9dbe",

		},
		"9": {
			position: "0%",
			text: "9",
			markerBgColor: "#1b9dbe",
		},
		"8": {
			position: "6.6%",
			text: "8",
			markerBgColor: "#1b9dbe",

		},
		"7": {
			position: "13.2%",
			text: "7",
			markerBgColor: "#1b9dbe",

		},
		"6": {
			position: "19.8%",
			text: "6",
			markerBgColor: "#1b9dbe",
		},
		"5": {
			position: "26.4%",
			text: "5",
			markerBgColor: "#1b9dbe",
		},
		"4": {
			position: "33%",
			text: "4",
			markerBgColor: "#1b9dbe",
		},
		"3": {
			position: "39.6%",
			text: "3",
			markerBgColor: "#1b9dbe",
		},
		"2": {
			position: "46.2%",
			text: "2",
			markerBgColor: "#1b9dbe",
		},
		"1": {
			position: "52.8%",
			text: "1",
			markerBgColor: "#1b9dbe",
		},
		"0": {
			position: "59.4%",
			text: "",
			markerBgColor: "#1b9dbe",
		},
		"-1": {
			position: "66%",
			text: "-1",
			markerBgColor: "#d5481e",
		},
		"-2": {
			position: "69.3%",
			text: "-2",
			markerBgColor: "#d5481e",
		},
		"-3": {
			position: "72.6%",
			text: "-3",
			markerBgColor: "#d5481e",
		},
		"-4": {
			position: "75.9%",
			text: "-4",
			markerBgColor: "#d5481e",
		},
		"-5": {
			position: "79.2%",
			text: "-5",
			markerBgColor: "#d5481e",
		},
		"-6": {
			position: "82.5%",
			text: "-6",
			markerBgColor: "#d5481e",
		},
		"-7": {
			position: "85.8%",
			text: "-7",
			markerBgColor: "#d5481e",
		},
		"-8": {
			position: "89.1%",
			text: "-8",
			markerBgColor: "#d5481e",
		},
		"-9": {
			position: "92.4%",
			text: "-9",
			markerBgColor: "#d5481e",
		},
		"-10": {
			position: "95.7%",
			text: "-10",
			markerBgColor: "#d5481e",
		},
		"defaultFallback": {
			position: "59.4%",
			text: "BASELINE",
			markerBgColor: "#1b9dbe",
		},

	}
}