import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";

export default function AdminProfile() {
  const [physician, setPhysician] = useState(null);
  const [editPhysician, setEditPhysician] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [editProfile, setEditProfile] = useState({ name : '', email : '', password : '', confirm_password : ''});
  const [editMode, setEditMode] = useState(false);
  const [profileEditMode, setProfileEditMode] = useState(false);
  const [profileErrors, setProfileErrors] = useState({});
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);

  const isPhysicianValid = () => {
    return true;
  };

  const savePhysician = () => {
    if (isPhysicianValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/admin/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(editPhysician),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setPhysician(response.adminProfile);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    }
  };

  const saveProfile = () => {
      setReady(false);
      if (editProfile.password === editProfile.confirm_password) {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(editProfile),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.success) {
              setUsername(editProfile.name);
              setEmail(editProfile.email);
              setProfileErrors({});
              setProfileEditMode(false);
            } else {
              setProfileErrors(response.error);
            }
            setReady(true);
          })
          .catch((error) => {
            console.error(error);
            setMessage("Some Error Occured. Please Try Again Later");
            setReady(true);
          });
      }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/admin/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPhysician(response.adminProfile);
          setEditPhysician({
            ...response.adminProfile,
            address: { ...response.adminProfile.address },
          });
          setUsername(response.adminProfile.clinic.name)
          setEmail(response.adminProfile.clinic.email)
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wide font-normal ${
          ready ? "" : "hidden"
        }`}
      >
        <nav className="flex items-center border-b-2 border-background-6 pb-4 mx-10">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
          <h1 className="mx-auto mt-4 text-3xl text-text-2 font-bold">
            {physician && (physician.clinic.name) }
          </h1>
        </nav>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex">
          <AdminSidebar />
          {physician && (
            <div className="mx-auto">
              <form
                className="flex flex-col"
                onSubmit={(event) => {
                  event.preventDefault();
                  savePhysician();
                }}
              >
                <div className="mt-4 flex justify-between items-center">
                  <button
                    type="button"
                    className=" px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  >
                    {editMode ? "Cancel" : "Edit"}
                  </button>
                  <p className="mx-auto text-text-2 font-bold">
                    PRACTICE INFORMATION
                  </p>
                  {editMode && <button
                    className=" px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none"
                    disabled={!editMode}
                  >
                    SAVE
                  </button>}
                </div>
                <div className="mx-auto mt-10 flex flex-col items-end">
                  <label className="flex items-center">
                    <p className="ml-2">PRACTICE NAME</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        editMode
                          ? editPhysician.practiceName
                          : physician.practiceName
                      }
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          practiceName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">PHYSICIAN NAME</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        editMode
                          ? editPhysician.physicianName
                          : physician.physicianName
                      }
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          physicianName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">ADDRESS</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        editMode
                          ? editPhysician.address.street
                          : physician.address.street
                      }
                      disabled={!editMode}
                      placeholder="Street"
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          address: {
                            ...editPhysician.address,
                            street: event.target.value,
                          },
                        });
                      }}
                    />
                  </label>
                  <input
                    type="text"
                    className="mt-4 ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={
                      editMode
                        ? editPhysician.address.suite
                        : physician.address.suite
                    }
                    disabled={!editMode}
                    placeholder="Suite"
                    onChange={(event) => {
                      setEditPhysician({
                        ...editPhysician,
                        address: {
                          ...editPhysician.address,
                          suite: event.target.value,
                        },
                      });
                    }}
                  />
                  <input
                    type="text"
                    className="mt-4 ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={
                      editMode
                        ? editPhysician.address.city
                        : physician.address.city
                    }
                    disabled={!editMode}
                    placeholder="City"
                    onChange={(event) => {
                      setEditPhysician({
                        ...editPhysician,
                        address: {
                          ...editPhysician.address,
                          city: event.target.value,
                        },
                      });
                    }}
                  />
                  <div className="mt-4 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editPhysician.address.state
                            : physician.address.state
                        }
                        disabled={!editMode}
                        placeholder="State"
                        onChange={(event) => {
                          setEditPhysician({
                            ...editPhysician,
                            address: {
                              ...editPhysician.address,
                              state: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editPhysician.address.zip
                            : physician.address.zip
                        }
                        disabled={!editMode}
                        placeholder="Zip"
                        onChange={(event) => {
                          setEditPhysician({
                            ...editPhysician,
                            address: {
                              ...editPhysician.address,
                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </div>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">PHONE</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editMode ? editPhysician.phone : physician.phone}
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">EMAIL</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editMode ? editPhysician.email : physician.email}
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">WEBSITE</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editMode ? editPhysician.website : physician.website}
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          website: event.target.value,
                        });
                      }}
                    />
                  </label>
                </div>
              </form>
              <form
                className="mt-16 flex flex-col"
                onSubmit={(event) => {
                  event.preventDefault();
                  saveProfile();
                }}
              >
                <div className="mt-4 flex items-center">
                  <button
                    type="button"
                    className=" px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none"
                    onClick={() => {
                      setEditProfile({ name : username, email : email, password : '', confirm_password : ''});
                      setProfileEditMode(!profileEditMode);
                    }}
                  >
                    {profileEditMode ? "Cancel" : "Edit"}
                  </button>
                  <p className="mx-auto text-text-2 font-bold">
                    CLINIC PROFILE
                  </p>
                  {profileEditMode && <button
                    className=" px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none "
                    disabled={!profileEditMode}
                  >
                    SAVE
                  </button>}
                </div>
                <div className="mx-auto mt-10 flex flex-col items-end bg-background-5 px-32 py-8">
                  <label className="flex items-center">
                    <p className="ml-2">CLINIC NAME</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        profileEditMode
                          ? editProfile.name
                          : username
                      }
                      required
                      disabled={!profileEditMode}
                      onChange={(event) => {
                        setEditProfile({
                          ...editProfile,
                          name: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {profileErrors.name && <span className="text-red-500">{profileErrors.name}</span>}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">EMAIL</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={
                        profileEditMode
                          ? editProfile.email
                          : email
                      }
                      required
                      disabled={!profileEditMode}
                      onChange={(event) => {
                        setEditProfile({
                          ...editProfile,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {profileErrors.email && <span className="text-red-500">{profileErrors.email}</span>}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">PASSWORD</p>
                    <input
                      type="password"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none" autoComplete="off"
                      value={
                        profileEditMode
                          ? editProfile.password
                          : ''
                      }
                      required
                      disabled={!profileEditMode}
                      onChange={(event) => {
                        setEditProfile({
                          ...editProfile,
                          password: event.target.value,
                        });
                      }}
                    />
                    
                  </label>
                  {profileErrors.password && <span className="text-red-500">{profileErrors.password}</span>}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">CONFIRM PASSWORD</p>
                    <input
                      type="password"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none" autoComplete="off"
                      value={
                        profileEditMode
                          ? editProfile.confirm_password
                          : ''
                      }
                      required
                      disabled={!profileEditMode}
                      onChange={(event) => {
                        setEditProfile({
                          ...editProfile,
                          confirm_password: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {profileErrors.confirm_password && <span className="text-red-500">{profileErrors.confirm_password}</span>}
                </div>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading message={"Loading Physician Details"} ready={ready} />
    </>
  );
}
