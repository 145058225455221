import React, { useContext, useState } from 'react';
import MaleHeadTop from "../../assets/images/MaleHeadTop.png";
import MaleHeadLeft from "../../assets/images/MaleHeadLeft.png";
import MaleHeadRight from "../../assets/images/MaleHeadRight.png";
import FemaleHeadTop from "../../assets/images/FemaleHeadTop.png";
import FemaleHeadLeft from "../../assets/images/FemaleHeadLeft.png";
import FemaleHeadRight from "../../assets/images/FemaleHeadRight.png";
import FemaleHeadOccipital from "../../assets/images/FemaleHeadOccipital.png";
import MaleHeadOccipital from "../../assets/images/MaleHeadOccipital.png";
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import DotGrey from "../../assets/images/DotGrey.svg";
import DotTurquoise from "../../assets/images/DotTurquoise.svg";
import { Link, useParams } from 'react-router-dom';
import { GenderContext } from '../../views/StudyMode';

const HEADS = {
	LEFT: 'left',
	RIGHT: 'right',
	CROWN: 'crown',
	VERTEX: 'vertex',
	FRONT: 'front',
	OCCIPITAL: 'occipital'
}


const ShowHeadAvatar = () => {
	const { patient_id, analysis_id, subType } = useParams();
	const [currentHead, setCurrentHead] = useState(subType);

	const { gender } = useContext(GenderContext);

	return (
		<div className='flex flex-col justify-center gap-4'>
			<div className='flex items-center justify-center'>
				<button
					className="font-bold text-2xl focus:outline-none"
					onClick={function () {
						const disableTopNavigation = currentHead === HEADS.OCCIPITAL;

						if (!disableTopNavigation) {
							setCurrentHead(HEADS.OCCIPITAL);
						}
					}}
				>
					<img src={lessThanIcon} className="h-6 w-6" style={{ transform: 'rotate(90deg)' }} alt='up-icon' />
				</button>
			</div>
			<div className="flex items-center justify-center w-full">
				<button
					className="font-bold text-2xl focus:outline-none"
					onClick={function () {
						const disableLeftNavigation = currentHead === HEADS.LEFT;

						if (!disableLeftNavigation) {
							setCurrentHead(prev => prev === HEADS.RIGHT ? HEADS.CROWN : HEADS.LEFT);
						}
					}}
				>
					<img src={lessThanIcon} className="h-6 w-6" />
				</button>
				{
					[HEADS.CROWN, HEADS.FRONT, HEADS.VERTEX].includes(currentHead) &&
					<div className='relative '>
						{
							gender === 'F' ? <img src={FemaleHeadTop} className="w-48 px-2" /> : <img src={MaleHeadTop} className="w-48 px-2" />
						}
						<div className='absolute top-0 flex flex-col items-center justify-around w-full h-full pb-8 w-48'>
							<div>
								<Link to={`/patient/${patient_id}/study/${analysis_id}/vertex`}>
									<ShowDot forType="vertex" subType={subType} />
								</Link>
							</div>
							<div>
								<Link to={`/patient/${patient_id}/study/${analysis_id}/crown`}>
									<ShowDot forType="crown" subType={subType} />
								</Link>
							</div>
							<div>
								<Link to={`/patient/${patient_id}/study/${analysis_id}/front`}>
									<ShowDot forType="front" subType={subType} />
								</Link>
							</div>
						</div>
					</div>
				}

				{
					currentHead === HEADS.LEFT &&
					<div className='relative '>
						{
							gender === 'F' ? <img src={FemaleHeadLeft} className="w-48 px-2" /> : <img src={MaleHeadLeft} className="w-48 px-2" />
						}
						<div className='absolute top-0 flex flex-col items-center justify-around w-full h-full pb-8'>
							<div>
								<Link to={`/patient/${patient_id}/study/${analysis_id}/left`}>
									<ShowDot forType="left" subType={subType} />
								</Link>
							</div>
							<div>
							</div>
							<div>
							</div>
						</div>
					</div>
				}

				{
					currentHead === HEADS.RIGHT &&
					<div className='relative '>
						{
							gender === 'F' ? <img src={FemaleHeadRight} className="w-48 px-2" /> : <img src={MaleHeadRight} className="w-48 px-2" />
						}
						<div className='absolute top-0 flex flex-col items-center justify-around w-full h-full pb-8'>
							<div>
								<Link to={`/patient/${patient_id}/study/${analysis_id}/right`}>
									<ShowDot forType="right" subType={subType} />
								</Link>
							</div>
							<div>
							</div>
							<div>
							</div>
						</div>
					</div>
				}
				{
					currentHead === HEADS.OCCIPITAL &&
					<div className='relative '>
						{
							gender === 'F' ? <img src={FemaleHeadOccipital} className="w-44 px-2" /> : <img src={MaleHeadOccipital} className="w-48 px-2" />
						}
						<div className='absolute top-0 flex flex-col items-center justify-around w-full h-full pb-8'>
							<div>
							</div>
							<div>
								<Link to={`/patient/${patient_id}/study/${analysis_id}/${HEADS.OCCIPITAL}`}>
									<ShowDot forType={HEADS.OCCIPITAL} subType={subType} />
								</Link>
							</div>
							<div>
							</div>
						</div>
					</div>
				}
				<button
					className="font-bold text-2xl focus:outline-none"
					onClick={function () {
						const disableRightNavigation = currentHead === HEADS.RIGHT;

						if (!disableRightNavigation) {
							setCurrentHead(prev => prev === HEADS.LEFT ? HEADS.CROWN : HEADS.RIGHT);
						}
					}}
				>
					<img src={greaterThanIcon} className="h-6 w-6" />
				</button>
			</div>
			<div className='flex items-center justify-center'>
				<button
					className="font-bold text-2xl focus:outline-none"
					onClick={function () {
						const allowBottomNavigation = currentHead === HEADS.OCCIPITAL;

						if (allowBottomNavigation) {
							setCurrentHead(HEADS.CROWN)
						}
					}}
				>
					<img src={lessThanIcon} className="h-6 w-6" style={{ transform: 'rotate(270deg)' }} alt='up-icon' />
				</button>
			</div>
		</div>

	)
}

const ShowDot = ({ forType, subType }) => {
	if (forType === subType) {
		return <img src={DotTurquoise} className='h-3 w-3 cursor-pointer' />
	}

	return <img src={DotGrey} className='h-3 w-3 cursor-pointer' />
};

export default ShowHeadAvatar