import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";

export default function AdminMedication() {
  // const [message, setMessage] = useState("");
  // const [success, setSuccess] = useState("");
  const message = "";
  const success = "";
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}>
        <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
          <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
            PACIFIC HAIR CENTER
          </h1>
        </nav>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex">
          <AdminSidebar />
          <div className="mx-auto max-w-2xl">
            <div className="flex flex-col items-center justify-start my-20">
              <div className="w-full flex justify-start items-center my-4">
                <div className="mx-8 p-2">
                  <svg
                    className="h-16 w-20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-40 0 560 560">
                    <path
                      fill="#fff"
                      d="M48 32h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48zm140.485 355.515l123.029-123.029c4.686-4.686 4.686-12.284 0-16.971l-123.029-123.03c-7.56-7.56-20.485-2.206-20.485 8.485v246.059c0 10.691 12.926 16.045 20.485 8.486z"
                    />
                  </svg>
                </div>
                <div className="mx-6 text-text-3">TUTORIALS</div>
              </div>
              <div className="w-full flex justify-start items-center my-4">
                <div className="mx-8 p-2">
                  <svg
                    className="h-16 w-20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
                    />
                  </svg>
                  <svg
                    className="h-16 w-20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z"
                    />
                  </svg>
                </div>
                <div className="mx-6 text-text-3">TECHNICAL SUPPORT</div>
              </div>
              <div className="w-full flex justify-start items-center my-4">
                <div className="mx-8 p-2">
                  <svg
                    className="h-16 w-20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
                    />
                  </svg>
                  <svg
                    className="h-16 w-20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z"/>
                  </svg>
                </div>
                <div className="mx-6 text-text-3">CUSTOMER SERVICE</div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Loading message={"Loading Support"} ready={ready} />
    </>
  );
}
