import { React, useState, useEffect } from "react";
import HairOverview from "./PatientSearch/HairOverview";
import GlobalImages from "./PatientSearch/GlobalImages";
import HairData from "./PatientSearch/HairData";
import HairDensityTracking from "./PatientSearch/HairDensityTracking";
import TreatmentPlan from "../views/TreatmentPlan";

export default function PatientViewMatch({
  selectedPatient,
  setSelectedPatient,
  setMessage,
  setReady,
}) {
  const [view, setView] = useState("overview");

  useEffect(() => {
    setReady(false);
    setMessage("");
  }, [setMessage, setReady, view]);

  return (
    <>
      <div className="my-2 grid grid-cols-3 font-font-2 font-normal text-text-13">
        <div></div>
        <div className="mx-8 flex items-center justify-between">
          <div className="w-8 h-8 flex bg-background-14 justify-center items-center">
            <p className="text-text-12 font-bold text-lg">
              {selectedPatient.id}
            </p>
          </div>
          <p>{{ M: "Male", F: "Female" }[selectedPatient.gender]}</p>
          <p>{selectedPatient.age} yrs</p>
          <p>{selectedPatient.plan}</p>
          <p className="text-accent-4">
            {selectedPatient.matchPercentage}% match
          </p>
        </div>
        <button
          className="ml-auto mr-4 focus:outline-none"
          onClick={() => setSelectedPatient(null)}
        >
          CLOSE
        </button>
      </div>
      {view === "overview" && (
        <HairOverview
          id={selectedPatient.id}
          setView={setView}
          setMessage={setMessage}
          setReady={setReady}
        />
      )}
      {view === "global-images" && (
        <GlobalImages
          id={selectedPatient.id}
          setView={setView}
          setMessage={setMessage}
          setReady={setReady}
        />
      )}
      {view === "hair-data" && (
        <HairData
          id={selectedPatient.id}
          setView={setView}
          setMessage={setMessage}
          setReady={setReady}
        />
      )}
      {view === "hair-density-tracking" && (
        <HairDensityTracking
          id={selectedPatient.id}
          setView={setView}
          setMessage={setMessage}
          setReady={setReady}
        />
      )}
      {view === "view-treatment-plan" && (
        <TreatmentPlan
          id={selectedPatient.id}
          setMessage={setMessage}
          setReady={setReady}
        />
      )}
    </>
  );
}
