import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";

export default function AdminMedication() {
  // const [message, setMessage] = useState("");
  // const [success, setSuccess] = useState("");
  const message = "";
  const success = "";
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}>
        <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
          <h1 className="mx-auto mt-4 text-3xl text-text-2 font-font-2 font-bold">
            PACIFIC HAIR CENTER
          </h1>
        </nav>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex">
          <AdminSidebar />
          <div className="mx-auto max-w-2xl">
            <div className="grid grid-cols-2 mt-8">
              <div className="px-4 flex flex-col items-center">
                <h2 className="font-font-2 text-xl mb-4">CONSENT FORM</h2>
                <div className="w-full text-center p-8 mb-4 bg-white">
                  <p>View </p>
                  <p>Consent Form</p>
                </div>
                <div className="w-full p-8 text-center mb-4 bg-white">
                  <p>Upload </p>
                  <p>Consent Form</p>
                </div>
              </div>
              <div className="px-4 flex flex-col items-center">
                <h2 className="font-font-2 text-xl mb-4">
                  CLINICAL NOTES TEMPLATES
                </h2>
                <div className="w-full text-center p-8 mb-4 bg-white">
                  <p>Clinical </p>
                  <p>Notes Templates</p>
                </div>
                <div className="w-full p-8 text-center mb-4 bg-white">
                  <p>Create </p>
                  <p>Clinical Notes Templates</p>
                </div>
              </div>
              <div className="px-4 flex flex-col items-center">
                <h2 className="font-font-2 text-xl mb-4">
                  PATIENT HEALTH FORM
                </h2>
                <div className="w-full text-center p-8 mb-4 bg-white">
                  <p>View & Edit </p>
                  <p>Patient Health Form</p>
                </div>
                <div className="w-full p-8 text-center mb-4 bg-white">
                  <p>Create New </p>
                  <p>Patient Health Form</p>
                </div>
              </div>
              <div className="px-4 flex flex-col items-center">
                <h2 className="font-font-2 text-xl mb-4">EXAM FROM</h2>
                <div className="w-full text-center p-8 mb-4 bg-white">
                  <p>View & Edit </p>
                  <p>Exam From</p>
                </div>
                <div className="w-full p-8 text-center mb-4 bg-white">
                  <p>Create New </p>
                  <p>Exam From</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Loading message={"Loading Medication Details"} ready={ready} />
    </>
  );
}
