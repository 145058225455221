import React, { useState, useEffect } from "react";
import PresentationNavbar from "./PresentationNavbar";
// import { Bar } from "react-chartjs-2";

const createHairData = () => {
  const entries = 3;
  let id = 1;
  const areas = ["front", "crown", "vertex", "back", "right", "left"];
  const rawimg = ["https://ik.imagekit.io/iza2plj5imi/Capture_FRHGTlFkt.PNG"];
  const aiimgs = [
    "https://ik.imagekit.io/visceailxwt/img1_2-PHDAXWRL.PNG",
    "https://ik.imagekit.io/visceailxwt/img2_N7ht64SknF.PNG",
    "https://ik.imagekit.io/visceailxwt/img3_LLCvMnG8Xe.PNG",
  ];
  const hairData = [];
  for (let i = 0; i < entries; i++) {
    hairData[i] = [];
    hairData[i]["created_at"] = `11-${17 + i}-2020`;
    hairData[i]["data"] = [];
    for (let j = 0; j < areas.length; j++) {
      hairData[i]["data"][areas[j]] = {
        id: id++,
        rawimg: rawimg[Math.floor(Math.random() * rawimg.length)],
        aiimg: aiimgs[Math.floor(Math.random() * aiimgs.length)],
        All: (Math.random() * 80 + 100).toFixed(0),
        T: (Math.random() * 80 + 100).toFixed(0),
        V: (Math.random() * 80 + 100).toFixed(0),
        TV: (Math.random() * 80 + 100).toFixed(0),
        FU: (Math.random() * 80 + 100).toFixed(0),
        HT: (Math.random() * 80 + 100).toFixed(0),
      };
    }
  }
  return hairData;
};

const HairData = ({ id, setMessage, setReady, setView }) => {
  const [viewMode, setViewMode] = useState("front");
  const [hairDensityViewMode, setHairDensityViewMode] = useState("A");
  const [imageViewMode, setImageViewMode] = useState("raw");
  const [hairData, setHairData] = useState([]);
  const [currentId, setCurrentId] = useState("-1");

  // const [data, setData] = useState(null);
  // const label = ["apple",'oranges']
  // const dataset = ["10",'17']

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setHairData(createHairData());
          setCurrentId(0);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
      });
  }, [id, setReady, setMessage]);

  const PrintImg = () => {
    if (
      hairData[currentId] &&
      imageViewMode === "raw" &&
      hairData[currentId]["data"][viewMode]["rawimg"]
    ) {
      return (
        <img
          className="w-full mt-2"
          style={{ width: "221px", height: "296px" }}
          src={hairData[currentId]["data"][viewMode]["rawimg"]}
          alt=""
        />
      );
    } else if (
      hairData[currentId] &&
      imageViewMode === "ai" &&
      hairData[currentId]["data"][viewMode]["aiimg"]
    ) {
      return (
        <img
          className="w-full mt-2"
          style={{ width: "221px", height: "296px" }}
          src={hairData[currentId]["data"][viewMode]["aiimg"]}
          alt=""
        />
      );
    } else {
      return <div className="text-center mt-4">No image available</div>;
    }
  };
  return (
    <>
      <div
        className="p-12 bg-background-5 overflow-hidden"
        style={{ height: "var(--height-2)" }}
      >
        <div className="flex items-center justify-center mx-auto font-font-2 text-text-8">
          <button
            className={`${
              viewMode === "front" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("front");
            }}
          >
            Front
          </button>
          <button
            className={`${
              viewMode === "crown" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("crown");
            }}
          >
            Crown
          </button>
          <button
            className={`${
              viewMode === "vertex" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("vertex");
            }}
          >
            Vertex
          </button>
          <button
            className={`${
              viewMode === "back" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("back");
            }}
          >
            Back
          </button>
          <button
            className={`${
              viewMode === "left" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("left");
            }}
          >
            Left
          </button>
          <button
            className={`${
              viewMode === "right" && "text-text-7"
            } focus:outline-none mx-2`}
            onClick={() => {
              setViewMode("right");
            }}
          >
            Right
          </button>
        </div>
        <div className="flex items-center justify-center mx-auto mt-10">
          <table className="table-auto border-opacity-40 border border-gray mt-2">
            <tbody>
              <tr>
                <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                  HD/A :{" "}
                  {hairData[currentId]
                    ? hairData[currentId]["data"][viewMode]["All"]
                    : ""}
                </td>
                <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                  HD/T :{" "}
                  {hairData[currentId]
                    ? hairData[currentId]["data"][viewMode]["T"]
                    : ""}
                </td>
                <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                  HD/V :{" "}
                  {hairData[currentId]
                    ? hairData[currentId]["data"][viewMode]["V"]
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                  T/V :{" "}
                  {hairData[currentId]
                    ? hairData[currentId]["data"][viewMode]["TV"]
                    : ""}
                </td>
                <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                  HD/FU :{" "}
                  {hairData[currentId]
                    ? hairData[currentId]["data"][viewMode]["FU"]
                    : ""}
                </td>
                <td className="border-opacity-40 border border-gray px-4 py-1 w-40 font-normal text-center text-sm">
                  HT :{" "}
                  {hairData[currentId]
                    ? hairData[currentId]["data"][viewMode]["HT"]
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="grid grid-cols-11 gap-4 mt-12">
          <div className="col-start-1 col-end-5 mx-12">
            <p className="text-base font-font-2 text-text-2 font-normal">
              Hair Density (HD)
            </p>
            <div className="mx-auto">
              <button
                className={`${
                  hairDensityViewMode === "A" && "text-text-2 "
                } text-sm font-normal focus:outline-none mr-2 font-font-2 `}
                onClick={() => {
                  setHairDensityViewMode("A");
                }}
              >
                A
              </button>
              <button
                className={`${
                  hairDensityViewMode === "T" && "text-text-3 "
                } text-sm font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => {
                  setHairDensityViewMode("T");
                }}
              >
                T
              </button>
              <button
                className={`${
                  hairDensityViewMode === "V" && "text-text-3 "
                } text-sm font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => {
                  setHairDensityViewMode("V");
                }}
              >
                V
              </button>
              <button
                className={`${
                  hairDensityViewMode === "T/V" && "text-text-3 "
                } text-sm font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => {
                  setHairDensityViewMode("T/V");
                }}
              >
                T/V
              </button>
              <button
                className={`${
                  hairDensityViewMode === "FU" && "text-text-3 "
                } text-sm font-normal focus:outline-none mx-2 font-font-2`}
                onClick={() => {
                  setHairDensityViewMode("FU");
                }}
              >
                FU
              </button>
            </div>
            <div>
              {/* <Bar
                  data={data}
                  options={{
                    scales: {
                      xAxes: [{
                        barPercentage: 0.7,
                        gridLines: {
                          zeroLineColor: "#ccc",
                          drawTicks: false
                          
                        },
                        ticks: {
                          autoSkip: false,
                          
                          maxRotation: 0,
                          padding: 3,
                          fontColor: "#bfc5ca",
                        },
                      
                      
                    }],
                    
                      yAxes: [
                        {
                          barPercentage: 1.0,
                          time: {
                            unit: 'Areas',
                          },
                          gridLines: {
                            zeroLineColor: "#ccc",
                            drawTicks: false
                            
                          },
                          ticks: {
                            autoSkip: false,
                            maxTicksLimit: 0,
                            display: false, //this removed the labels on the x-axis
                            beginAtZero: true,
                            
                            
                          },
                          'dataset.maxBarThickness': 16,
                        }],
                    },
                    legend: {
                      labels:{
                        boxWidth: -15,
                        fontSize: 15,
                        fontColor: "#bfc5ca"
                      },
                      
                      align: "start",
                      onClick: "",
                    
                    },
                  }}
                /> */}
            </div>
          </div>
          <div className="col-start-5 col-end-8 px-4 text-sm">
            <div className="grid grid-cols-6 gap-6" style={{ width: "221px" }}>
              <div className="col-start-1 col-end-3">
                <button
                  className={`${
                    imageViewMode === "raw" && "text-text-2"
                  } text-sm font-normal focus:outline-none font-font-2`}
                  onClick={() => {
                    setImageViewMode("raw");
                  }}
                >
                  Raw
                </button>
              </div>
              <div className="col-end-7 col-span-2 text-right">
                <button
                  className={`${
                    imageViewMode === "ai" && "text-text-2"
                  } text-sm font-normal focus:outline-none font-font-2`}
                  onClick={() => {
                    setImageViewMode("ai");
                  }}
                >
                  A.I.
                </button>
              </div>
            </div>
            <div>
              <PrintImg />
            </div>
          </div>
          <div className="col-start-8 col-end-12">
            <p className="text-base font-font-2 text-text-2 font-normal mx-12">
              Hair Thickness (HT)
            </p>
          </div>
        </div>
      </div>
      <PresentationNavbar tab={3} setView={setView} />
    </>
  );
};

export default HairData;
