import { React, useState, useEffect, Fragment } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';

export default function PatientMakePayment() {
  const { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [treatmentPlans, setTreatmentPlans] = useState(null);
  // const [fee, setFee] = useState(0);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  let statFee = 0;
  const [patientReferrals, setPatientReferrals] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/paid-treatments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        // console.log(response);
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setTreatmentPlans(response.payments);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage('Some Error Occured. Please Try Again Later');
        setReady(true);
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={5} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}

        <section className="font-font-2">
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          <div className="flex flex-col items-center mt-2 md:justify-center md:flex-row">
            <NavLink
              exact
              to={`/patient/payment/make-payment/${id}`}
              activeClassName="text-text-2"
            >
              Make Payment
            </NavLink>
            <div className="w-2 h-2 mx-8 my-2 rounded-full bg-accent-1"></div>
            <NavLink
              exact
              to={`/patient/payment/view-payment-history/${id}`}
              activeClassName="text-text-2"
            >
              View Payment History
            </NavLink>
          </div>
          <div
            className="mt-4 bg-background-5 py-4 px-16 flex flex-col"
            style={{ height: 'var(--height-1)', overflowY: 'auto' }}
          >
            <h1 className="mb-4 text-xl text-center text-text-2">Payments</h1>
            {treatmentPlans &&
              Object.keys(treatmentPlans).map((date, idx) => (
                <Fragment key={idx}>
                  {(treatmentPlans[date]?.procedures.length > 0 ||
                    treatmentPlans[date]?.medications.length > 0 ||
                    treatmentPlans[date]?.products.length > 0) && (
                    <h2 className="mt-4">{dayjs(date).format('DD-MM-YYYY')}</h2>
                  )}
                  {treatmentPlans[date]?.procedures.length > 0 ? (
                    <h2 className="mt-4">Procedures</h2>
                  ) : null}
                  {treatmentPlans[date]?.procedures?.map((procedure, index) => {
                    statFee += Number(procedure.fee);
                    return (
                      <Fragment key={index}>
                        <div className="grid grid-cols-6 mt-2 font-normal text-center text-text-3">
                          <h2 className="px-2 py-1 font-bold bg-background-6">
                            {procedure.name}
                          </h2>
                          <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                            {procedure.brand}
                          </p>
                          <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                            {procedure.tx}
                          </p>
                          <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                            {procedure.interval}
                          </p>
                          <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                            {procedure.area}
                          </p>
                          <p className="w-full px-2 py-1 bg-background-7">
                            ${Number(procedure.fee).toLocaleString()}
                          </p>
                        </div>
                      </Fragment>
                    );
                  })}
                  {treatmentPlans[date]?.medications.length > 0 ? (
                    <h2 className="mt-4">Medications</h2>
                  ) : null}
                  {treatmentPlans[date]?.medications?.map(
                    (medication, index) => {
                      statFee += Number(medication.fee);
                      return (
                        <Fragment key={index}>
                          <div className="grid grid-cols-6 mt-2 font-normal text-center text-text-3 font-font-2">
                            <h2 className="px-2 py-1 font-bold bg-background-6">
                              {medication.name}
                            </h2>
                            <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                              {medication.brand}
                            </p>
                            <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                              {medication.dosage}
                            </p>
                            <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                              {medication.number}
                            </p>
                            <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                              {medication.refill}
                            </p>
                            <p className="w-full px-2 py-1 bg-background-7">
                              ${Number(medication.fee).toLocaleString()}
                            </p>
                          </div>
                        </Fragment>
                      );
                    }
                  )}
                  {treatmentPlans[date]?.products.length > 0 ? (
                    <h2 className="mt-4">Products</h2>
                  ) : null}
                  {treatmentPlans[date]?.products?.map((product, index) => {
                    statFee += Number(product.fee);
                    return (
                      <Fragment key={index}>
                        <div className="grid grid-cols-6 mt-2 font-normal text-center text-text-3 font-font-2">
                          <h2 className="px-2 py-1 font-bold bg-background-6">
                            {product.name}
                          </h2>
                          <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                            {product.brand}
                          </p>
                          <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                            {product.volume}
                          </p>
                          <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                            {product.number}
                          </p>
                          <p className="w-full px-2 py-1 border-r border-background-6 bg-background-7">
                            {product.delivery}
                          </p>
                          <p className="w-full px-2 py-1 bg-background-7">
                            ${Number(product.fee).toLocaleString()}
                          </p>
                        </div>
                      </Fragment>
                    );
                  })}
                </Fragment>
              ))}

            <div className="mx-auto mt-16 text-text-2">
              <h3 className="font-normal text-center border-b-2 border-text-1">
                Total Payments:
                {statFee
                  ? `${Number(statFee).toLocaleString('en-us', {
                      minimumFractionDigits: 2,
                    })}`
                  : `$0`}
              </h3>
              <button className="w-full px-10 py-1 mt-6 font-bold rounded-lg bg-accent-1 text-text-3 focus:outline-none">
                STATEMENTS
              </button>
            </div>
          </div>
        </section>
      </main>
      <Loading message={'Loading Payment Information'} ready={ready} />
    </>
  );
}
