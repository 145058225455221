import dayjs from 'dayjs';
import React from 'react';

const ViewExamData = ({
  data,
  editHairLossClass,
  setEditHairLossClass,
  setEditBloodworkExam,
  index,
  editBloodworkExam,
  editMode,
}) => {
  const handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (editBloodworkExam) {
      const editBloodwork = editBloodworkExam['blood_work_test'];
      editBloodwork[index][name] = value;
      setEditBloodworkExam({ blood_work_test: editBloodwork });
    } else if (editHairLossClass) {
      const editHairLoss = editHairLossClass['hair_loss_class'];
      editHairLoss[index][name] = value;
      setEditHairLossClass({ hair_loss_class: editHairLoss });
    }
  };

  return (
    <>
      {editBloodworkExam && editBloodworkExam ? (
        <>
          <div className="grid ml-6 gap-5">
            {editMode ? (
              <input
                type="date"
                value={data.date}
                name="date"
                onChange={e => handleInputChange(e)}
                className="px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
              />
            ) : (
              <p className="text-center">
                {dayjs(data.date).format('MM/DD/YYYY')}
              </p>
            )}
            <input
              type="text"
              name="cbc"
              value={data.cbc}
              onChange={e => handleInputChange(e)}
              disabled={!editMode}
              className="px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
            />
            <input
              type="text"
              name="platelet_count"
              value={data.platelet_count}
              onChange={e => handleInputChange(e)}
              disabled={!editMode}
              className="px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
            />
            <input
              type="text"
              name="estorgen"
              value={data.estorgen}
              onChange={e => handleInputChange(e)}
              disabled={!editMode}
              className="px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
            />
            <input
              type="text"
              name="testistron"
              value={data.testistron}
              onChange={e => handleInputChange(e)}
              disabled={!editMode}
              className="px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
            />
            <input
              type="text"
              name="thyroid"
              value={data.thyroid}
              onChange={e => handleInputChange(e)}
              disabled={!editMode}
              className="px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
            />
            <input
              type="text"
              name="iron"
              value={data.iron}
              onChange={e => handleInputChange(e)}
              disabled={!editMode}
              className="px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-evenly ml-2">
          {editMode ? (
            <input
              type="date"
              value={data.date}
              name="date"
              onChange={e => handleInputChange(e)}
              className="w-full h-10 px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
            />
          ) : (
            <p className="text-center">
              {dayjs(data.date).format('MM/DD/YYYY')}
            </p>
          )}
          <input
            type="text"
            name="gro_scale"
            value={data.gro_scale}
            disabled={!editMode}
            onChange={e => handleInputChange(e)}
            className="px-2 py-1 bg-background-2 h-10 text-center text-text-2 rounded-sm focus:outline-none"
          />
          <input
            type="text"
            name="ludwig"
            value={data.ludwig}
            onChange={e => handleInputChange(e)}
            disabled={!editMode}
            className="px-2 py-1 bg-background-2 h-10 text-center text-text-2 rounded-sm focus:outline-none"
          />
          <input
            type="text"
            name="savin"
            value={data.savin}
            onChange={e => handleInputChange(e)}
            disabled={!editMode}
            className="px-2 py-1 bg-background-2 h-10 text-center text-text-2 rounded-sm focus:outline-none"
          />
          <input
            type="text"
            name="norwood"
            value={data.norwood}
            onChange={e => handleInputChange(e)}
            disabled={!editMode}
            className="px-2 py-1 bg-background-2 h-10 text-center text-text-2 rounded-sm focus:outline-none"
          />
        </div>
      )}
    </>
  );
};

export default ViewExamData;
