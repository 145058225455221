import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Loading from "../views/Loading";
import { useHistory } from 'react-router-dom';

export default function OnboardingAdminProfile() {
  const history = useHistory();
  const [physician, setPhysician] = useState(null);
  const [editPhysician, setEditPhysician] = useState(null);
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("")
  const [ready, setReady] = useState(false);

  const isPhysicianValid = () => {
    return true;
  };

  const savePhysician = () => {
    if (isPhysicianValid()) {
      setErrors([]);
      setMessage("");
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/admin/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(editPhysician),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setPhysician(response.adminProfile);
            setEditPhysician(response.adminProfile);
            setSuccessMessage("Profile Updated Successfully");
            setTimeout(() => {
              history.push("/welcome");
            }, 1000);
          } else {
            setMessage(response.message);
            setErrors(response.error);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage("Some Error Occured. Please Try Again Later");
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/admin/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPhysician(response.adminProfile);
          setEditPhysician({
            ...response.adminProfile,
            address: { ...response.adminProfile.address },
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wide font-normal ${
          ready ? "" : "hidden"
        }`}
      >
        <nav className="flex items-center border-b-2 border-background-6 pb-4 mx-10">
          <NavLink to="/">
            <img className="h-20" src={Logo} alt="GRO Track" />
          </NavLink>
          <h1 className="mx-auto mt-4 text-3xl text-text-2 font-bold">
            {physician && (physician.clinic.name) }
          </h1>
        </nav>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {successMessage && <p className="mt-8 text-center text-green-600">{successMessage}</p>}
        <section className="flex">
          {editPhysician && (
            <div className="mx-auto">
              <form
                className="flex flex-col"
              >
                <div className="mt-4 flex justify-between items-center">
                  <p className="mx-auto text-text-2 font-bold">
                    PRACTICE INFORMATION
                  </p>
                </div>
                <div className="mx-auto mt-10 flex flex-col items-end">
                  <label className="flex items-center">
                    <p className="ml-2">PRACTICE NAME</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editPhysician.practiceName}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          practiceName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {errors.practiceName && <p className="text-center text-red-600">{errors.practiceName}</p>}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">PHYSICIAN NAME</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editPhysician.physicianName}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          physicianName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {errors.physicianName && <p className="text-center text-red-600">{errors.physicianName}</p>}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">ADDRESS</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editPhysician.address.street}
                      placeholder="Street"
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          address: {
                            ...editPhysician.address,
                            street: event.target.value,
                          },
                        });
                      }}
                    />
                  </label>
                  <input
                    type="text"
                    className="mt-4 ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={editPhysician.address.suite}
                    placeholder="Suite"
                    onChange={(event) => {
                      setEditPhysician({
                        ...editPhysician,
                        address: {
                          ...editPhysician.address,
                          suite: event.target.value,
                        },
                      });
                    }}
                  />
                  <input
                    type="text"
                    className="mt-4 ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                    value={editPhysician.address.city}
                    placeholder="City"
                    onChange={(event) => {
                      setEditPhysician({
                        ...editPhysician,
                        address: {
                          ...editPhysician.address,
                          city: event.target.value,
                        },
                      });
                    }}
                  />
                  <div className="mt-4 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                        value={editPhysician.address.state}
                        placeholder="State"
                        onChange={(event) => {
                          setEditPhysician({
                            ...editPhysician,
                            address: {
                              ...editPhysician.address,
                              state: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                        value={editPhysician.address.zip}
                        placeholder="Zip"
                        onChange={(event) => {
                          setEditPhysician({
                            ...editPhysician,
                            address: {
                              ...editPhysician.address,
                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </div>
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">PHONE</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editPhysician.phone}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {errors.phone && <p className="text-center text-red-600">{errors.phone}</p>}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">EMAIL</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editPhysician.email}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {errors.email && <p className="text-center text-red-600">{errors.email}</p>}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">WEBSITE</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 font-bold rounded-sm focus:outline-none"
                      value={editPhysician.website}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          website: event.target.value,
                        });
                      }}
                    />
                  </label>
                </div>

                <div className="mx-auto mt-4 flex flex-col items-center">
                  <button
                    className="px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none"
                    onClick={(event) => {
                      event.preventDefault();
                      savePhysician();
                    }}
                  >
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading message={"Loading Physician Details"} ready={ready} />
    </>
  );
}
